
<div class="row justify-content-center">
    <div class="col-12">

        <div *ngIf="!multipleAttachs()">
            <img src="{{ getDefaultImage() }}" alt="Thumbnail Image" class="rounded img-raised">
        </div>

        <ngb-carousel id="carousel" [interval]="2000" *ngIf="multipleAttachs()">
            <ng-template ngbSlide *ngFor="let a of attachs">
                <img class="d-block" src="{{ getImage(a) }}" alt="Attachment">
                <div class="carousel-caption d-none d-md-block">
                </div> 
            </ng-template>

        </ngb-carousel>
    </div>
</div>