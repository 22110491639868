import { Injectable } from '@angular/core';
import { Team } from '../interfaces/team';
import teamJson from '../../assets/team.json';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor() {}

  team: Team[] = teamJson;
	

	getTeam(): Team[] {
		return this.team; 
	}

	
}
