import { Component, OnInit } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-total-highlights",
	templateUrl: "./total-highlights.component.html",
	styleUrls: ["./total-highlights.component.scss"],
})
export class TotalHighlightsComponent implements OnInit {
	totalHighlights: Project[] = [];


	constructor(private projectsService: ProjectsService) {}
	ngOnInit() {
		this.getProjects();
	}
  
	getProjects(): void {
		this.totalHighlights = this.projectsService.getStaffAll();
	}

	getImage(r : any){
		return r?.attach[0]?.src || r?.attach[0];
	}
}
