<div class="wrapper">
  
    <div class="features-4">
        <h2 class="title" style="text-align: center;">
            Buniness Intelligence enterprise starter pack
        </h2>
        
        <div class="container">
            <div class="row">
                <div class="col-md-12 ml-auto mr-auto text-center">
                    <h3 class="description">
                        <small>
                            Our team at Brighten is thrilled to introduce our Digital Analytics Starter Pack, a comprehensive suite of essential business dashboards carefully crafted to empower high-performing companies with actionable insights.
                            Explore the full potential of our analytics dashboards in our interactive playground and discover how you can transform your data into a strategic advantage for your business.
                        </small> 
                    </h3>
                </div>
            </div>

        </div>
    </div>

    <div class="cards">
        <div class="container" style="padding:0px"> 
            <div class="row">
                <div class="col-md-6 col-lg-4" *ngFor="let item of bi" style="position: relative;">
                    <div class="card card-blog card-plain">
                        <div class="card-image">
                            <a target="_blank" href="{{item.url}}">
                                <img class="img rounded img-raised" src="{{item.image}}">
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="category text-warning" style="font-size: 1em !important;">
                                <i class="now-ui-icons business_bulb-63"></i> {{item.category}}
                            </h6>
                            <h5 class="card-title">
                                <a href="{{item.url}}">{{item.title}}</a>
                            </h5>
                            <p class="card-description" style="margin-bottom: 0px;">
                                {{item.summary}}
                            </p>
                            <div class="card-footer" style="position: relative;">
                                <div class="stats" style="right: 25px !important;position: absolute;top: 18px !important;">
                                    <i class="now-ui-icons tech_watch-time"></i> {{item.kpis}}
                                </div>
                                <ngbd-modal-high-component [proj]="item" [text]="'Read more'" [imageButton]="false" [multipleViews]="true"></ngbd-modal-high-component>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="features-4">
        
        <div class="container">
            <div class="row">
                <div class="col-md-12 ml-auto mr-auto text-center">
                    <h4 class="description"></h4>
                </div>
            </div>

        </div>
    </div>

</div>
