<div class="cd-section" id="teams" *ngIf="visible">
    <div id="team" class="team-4">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Meet the team</h2>
                    <h4 class="description">We're a team of very dedicated and
                        supportive people who want to develop helpful and
                        innovative solutions worlwide. <p></p>We work together and get
                        along wonderfully. That translates into cohesive, warm
                        and whole work. We are happier and stronger together!</h4>
                </div>
            </div>
            <div class="row" style="margin-top:20px;background-color: #fff; border-radius: 25px;padding-top:10px" *ngIf="teamVisible">
                <div class="col-md-6" *ngFor="let p of team">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-image" style="text-align: center;">
                                    <img class="img img-raised rounded" src="{{p.photo}}" />
                                </div>
                            </div>
                            <div class="col-md-7" style="padding-left: 10px;">
                                <div class="card-body" style="padding-top: 0px;">
                                    <h4 class="card-title" style="margin-top:10px;margin-bottom:0px">{{p.name}}</h4>
                                    <h6 class="category">{{p.job}} <a style="margin:0" href="{{p.linkedin}}" class="btn btn-icon btn-neutral btn-linkedin"><i class="fa fa-linkedin"></i></a></h6>
                                    <p class="card-description">
                                        {{p.description}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
