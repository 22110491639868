import {Component, Input} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'app/interfaces/project';

@Component({
  selector: 'ngbd-modal-high-component',
  templateUrl: './modal-high.component.html'
})
export class ModalHighComponent {
    closeResult: string; 
    @Input() proj: Project;
    @Input() imageButton: boolean = false;
    @Input() multipleViews: boolean = false;
    safeDescription: SafeHtml;
    isPreview: boolean = false;
    @Input() text: string = 'Learn more';

    ngOnInit(){
        this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(this.proj.description);
    }
    
    constructor(
        private modalService: NgbModal,
        private sanitizer: DomSanitizer
    ) {}

    open(content, type, modalDimension = 'md') {
        debugger;
        if (modalDimension === 'sm' && type === 'modal_mini') {
            this.modalService.open(content, { windowClass: 'modal-mini modal-primary', size: 'sm' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else if (modalDimension === 'md' && type === 'Login') {
          this.modalService.open(content, { windowClass: 'modal-login modal-primary', size: 'md' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        } else {
            this.modalService.open(content, {windowClass: 'modal-secondary', size: modalDimension}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }

    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }


    public preview(value : boolean){
        this.isPreview = value;
    }

    navigateToExternalLink(url) {
        window.open(url, '_blank');
      }
}
