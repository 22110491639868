import { Component, OnInit } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-analytics-area",
	templateUrl: "./analytics-area.component.html",
	styleUrls: ["./analytics-area.component.scss"],
})
export class AnalyticsAreaComponent implements OnInit {
	projs: Project[] = []; 
	analyticsProjs: Project[] = [];
	focus : boolean = false;

	constructor(
		private projectsService: ProjectsService
	) {}

	ngOnInit() {
		this.getProjects();
	}



	getProjects(): void {
		this.projs = this.projectsService.getProjects();
    	this.analyticsProjs = this.projectsService.getAnalytics();
	}

	search(keywords : string = null){
		if(keywords == null || keywords == ''){
			this.getProjects();
			return;
		}

		keywords = keywords.toLowerCase();
		this.analyticsProjs = this.projectsService.getAnalytics().filter(x=> {
			return x.area.toLowerCase().includes(keywords) ||
				x.description.toLowerCase().includes(keywords) ||
				x.industry.toLowerCase().includes(keywords) ||
				x.location.toLowerCase().includes(keywords) ||
				x.short.toLowerCase().includes(keywords) ||
				x.title.toLowerCase().includes(keywords)
		});
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0];
	}

}
