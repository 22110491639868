<div style="background-color:#f6f6f6 !important; padding: 20px 0;">
    <div class="container">
        <div class="col-md-10 ml-auto mr-auto">
            <div class="section-description text-center">
                <h2 class="title" style="padding-top:0">Our projects</h2>
                <h5 class="description">
                    We have a diverse and distinct portfolio.<br>
                    If you are looking for smart solutions with your data or new innovation approaches<br>count on <span style="text-decoration:underline">our dedicated support</span>.
                    <br><br><br>These are some of our hand-picked projects we believe you'll love.</h5>
            </div>
        </div>
    </div>
    <div class="container-fluid" style="padding-top:30px">
        <div class="section-cols">
            <div class="row" style="margin-left: 10px;margin-right: 5px;">
                <div class="col-md-3" *ngFor="let high of totalHighlights">
                    <div class="card card-background card-raised2" data-background-color style="background-image:url('{{ getImage(high) }}')"> 
                    <div class="info">
                        <div class="description">
                            <h4 class="info-title">{{high.title}}</h4>
                            <div class="darkbox-text">{{high.short}}</div>
                            <ngbd-modal-high-component [proj]="high"></ngbd-modal-high-component>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row" style="padding-top: 50px;">
            <div class="col-md-10 ml-auto mr-auto"> 
                <h2 class="text-center title">more projects</h2>

                <h5 class="text-center description">
                    Search our complete portfolio by technology or industry.
                </h5>                    
            </div>
        </div>
        <div class="row" style="margin-right:0px !important;margin-left:0px !important;">
            <div class="col-md-3">
                <div class="card-container first-card">
                    <div class="card-component">
                        <a [routerLink]="['/tech/software']" target="_blank">
                            <div class="front">
                                <img src="assets/img/presentation-page/software_eng2.PNG">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-container second-card">
                    <div class="card-component">
                        <a [routerLink]="['/tech/analytics']" target="_blank">
                            <div class="front">
                                <img
                                    src="assets/img/presentation-page/analytics2.PNG">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-container third-card">
                    <div class="card-component">
                        <a [routerLink]="['/tech/rpa']" target="_blank">
                            <div class="front">
                                <img
                                    src="assets/img/presentation-page/RPA2.png">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card-container fourth-card">
                    <div class="card-component">
                        <a [routerLink]="['/tech/industry']" target="_blank">
                            <div class="front">
                                <img src="assets/img/presentation-page/staff.png">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>