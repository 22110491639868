import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-area',
  templateUrl: './bi.component.html',
  styleUrls: ['./bi.component.scss']
})
export class BiComponent implements OnInit {
  constructor(){
    
  }
 
  ngOnInit() {
    
  }

}
