<div class="wrapper" style="background-color: #eee;">
    <div class="features-4">
        <h2 class="title" style="text-align: center;">Brighten Digital solution portfolio</h2>
        <app-highlights-staff id="staff-high"></app-highlights-staff>

        <div id="all-areas" class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <p></p>
                    <h2 class="title" style="text-align: center;">All projects - areas</h2>
                    <h4 class="description">Here are our projects, grouped by area. We developed them with all our effort and passion. </h4>
                </div>
            </div>

            <app-analytics-area id="analytics-area"></app-analytics-area>

            <app-rpa-area id="rpa-area"></app-rpa-area>

            <app-software-area id="software-area"></app-software-area>

        </div>
    </div>
</div>
