import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule, routes } from "./app.routing";
import { SectionsModule } from "./sections/sections.module";

import { AppComponent } from "./app.component";

import { HomeModule } from "./home/home.module";
import { NavComponent } from "./shared/nav/nav.component";
import { AboutModule } from "./pages/about/about.module";
import { AreasComponent } from "./pages/areas/areas.component";
import { BulletsComponent } from "./components/bullets/bullets.component";
import { ComponentsModule } from "./components/components.module";

import { IndustriesComponent } from "./pages/industries/industries.component";
import { BiComponent } from "./pages/areas/bi/bi.component";
import { RPAComponent } from "./pages/areas/rpa/rpa.component";
import { SoftwareComponent } from "./pages/areas/software/software.component";
import { AboutComponent } from "./pages/about/about.component";
import { CommonModule } from "@angular/common";
import { StarterPackComponent } from "./pages/starter-pack/starter-pack.component";


@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		AreasComponent,
		BiComponent,
		RPAComponent,
		SoftwareComponent,
		BulletsComponent,
		IndustriesComponent,
		StarterPackComponent
	],
	providers: [],
	bootstrap: [AppComponent],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		AppRoutingModule,
		SectionsModule,
		HomeModule,
		ComponentsModule,
		AboutModule,
		RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled',
		scrollOffset: [0, 64]})
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
