import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-pharma-ind",
	templateUrl: "./pharma-ind.component.html",
	styleUrls: ["./pharma-ind.component.scss"],
})
export class PharmaIndComponent implements OnInit, OnChanges {
	pharmaProjs: Project[] = [];

	@Input() keywords : string = null;

	constructor(private projectsService: ProjectsService) {}

	ngOnInit() {
		this.getProjects();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.getProjects();
	}

	getProjects(): void {
		this.pharmaProjs = this.projectsService.getPharma(this.keywords);
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0];
	}

}
