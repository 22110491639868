<div class="section section-plain-cards" style="display:none">
    <div class="container">
        <div class="title">
            <div style="display:flex;align-items: center;margin-bottom: 15px;">
                <button class="btn btn-danger" style="margin-right: 15px;">
                    <i class="now-ui-icons ui-2_favourite-28"></i> 
                    NEW
                </button>
                <h3 style="flex:1;margin:0">
                    BI Starter pack
                </h3>
            </div>
            <h3>
                <small>Our team at Brighten is thrilled to introduce our Digital Analytics Starter-Pack, a comprehensive set of key business dashboards that have been carefully crafted to provide essential insights for a high performing company.</small>
            </h3>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="card card-blog card-plain">
                    <div class="card-image">
                        <a target="_blank" href="https://app.powerbi.com/view?r=eyJrIjoiMTY4MGIyMmYtZWYzYy00Y2IxLWI1NzAtNjJhMDFkMmU0MDUxIiwidCI6IjM0ZjUzMDg4LTFhNTgtNDY2Mi05MWM0LWY4Y2QyNzU0NGJjMCIsImMiOjh9">
                            <img class="img rounded img-raised" src="assets/img/bisp/logistics.png">
                        </a>
                    </div>
                    <div class="card-body">
                        <h6 class="category text-warning">
                            <i class="now-ui-icons business_bulb-63"></i> Logistics & Warehouse
                        </h6>
                        <h5 class="card-title">
                            <a href="https://app.powerbi.com/view?r=eyJrIjoiMTY4MGIyMmYtZWYzYy00Y2IxLWI1NzAtNjJhMDFkMmU0MDUxIiwidCI6IjM0ZjUzMDg4LTFhNTgtNDY2Mi05MWM0LWY4Y2QyNzU0NGJjMCIsImMiOjh9">Logistics</a>
                        </h5>
                        <p class="card-description">
                            Overview your logistics and warehouse activity. Goods receipt, inventory, replenishment, packaging and more.
                        </p>
                        <div class="card-footer">
                            <div class="stats">
                                <i class="now-ui-icons tech_watch-time"></i> > 50 KPIs
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card card-blog card-plain">
                    <div class="card-image">
                        <a target="_blank" href="https://app.powerbi.com/view?r=eyJrIjoiNDZkMjc1ODktNGZkNy00MTY3LTgwNTEtNDI3OTBmNzAwYjhiIiwidCI6IjM0ZjUzMDg4LTFhNTgtNDY2Mi05MWM0LWY4Y2QyNzU0NGJjMCIsImMiOjh9">
                            <img class="img rounded img-raised" src="assets/img/bisp/sales.png">
                        </a>
                    </div>
                    <div class="card-body">
                        <h6 class="category text-primary">Sales & Supply chain</h6>
                        <h5 class="card-title">
                            <a href="https://app.powerbi.com/view?r=eyJrIjoiNDZkMjc1ODktNGZkNy00MTY3LTgwNTEtNDI3OTBmNzAwYjhiIiwidCI6IjM0ZjUzMDg4LTFhNTgtNDY2Mi05MWM0LWY4Y2QyNzU0NGJjMCIsImMiOjh9">Sales</a>
                        </h5>
                        <p class="card-description">
                            Global sales activity monitoring, compared against previous periods, per location and product line.
                        </p>
                        <div class="card-footer">
                            <div class="stats">
                                <i class="now-ui-icons tech_watch-time"></i> > 50 KPIs
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card card-blog card-plain">
                    <div class="card-image">
                        <a target="_blank" href="https://app.powerbi.com/view?r=eyJrIjoiZWMzNTk1NWMtODcxMC00NTdjLWJhMDgtODgwMmY3ODliYzdiIiwidCI6IjM0ZjUzMDg4LTFhNTgtNDY2Mi05MWM0LWY4Y2QyNzU0NGJjMCIsImMiOjh9">
                            <img class="img img-raised rounded" src="assets/img/bisp/procurement.png">
                        </a>
                    </div>
                    <div class="card-body">
                        <h6 class="category text-danger">
                            <i class="now-ui-icons media-2_sound-wave"></i> Procurement
                        </h6>
                        <h5 class="card-title">
                            <a target="_blank" href="https://app.powerbi.com/view?r=eyJrIjoiZWMzNTk1NWMtODcxMC00NTdjLWJhMDgtODgwMmY3ODliYzdiIiwidCI6IjM0ZjUzMDg4LTFhNTgtNDY2Mi05MWM0LWY4Y2QyNzU0NGJjMCIsImMiOjh9">
                                Procurement & Purchasing
                            </a>
                        </h5>
                        <p class="card-description">
                            Track and optimize procurement activity and performance while improving reporting and call-to-action ratio.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="display:none">
    <app-clients></app-clients>
</div> 