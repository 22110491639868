import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NouisliderModule } from "ng2-nouislider";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { TagInputModule } from "ngx-chips";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { NgxGalleryModule } from "@kolkov/ngx-gallery";
import { ImageUploadModule } from "../shared/image-upload/image-upload.module";
import { RouterModule } from "@angular/router";

import { BasicelementsComponent } from "./basicelements/basicelements.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { TypographyComponent } from "./typography/typography.component";
import { NucleoiconsComponent } from "./nucleoicons/nucleoicons.component";
import { ComponentsComponent } from "./components.component";
import { NotificationComponent } from "./notification/notification.component";
import { NgbdModalBasic } from "./modal/modal.component";
import { PrefooterAreasComponent } from "./prefooter-areas/prefooter-areas.component";
import { FooterMainComponent } from "./footer-main/footer-main.component";
import { FootersAreasComponent } from "./footers-areas/footers-areas.component";
import { TablesAreasComponent } from "./tables-areas/tables-areas.component";
import { CardsAreasComponent } from "./cards-areas/cards-areas.component";
import { ModalHighComponent } from "./modal highlights/modal-high.component";
import { HighlightsStaffComponent } from "./highlights-areas/highlights-staff/highlights-staff.component";
import { HighlightsStaffIndComponent } from "./highlights-industries/highlights-staff-ind/highlights-staff-ind.component";
import { ClientsComponent } from "./clients/clients.component";

import { SlideshowComponent } from "./slideshow/slideshow.component";
import { AnalyticsAreaComponent } from "./highlights-areas/analytics-area/analytics-area.component";
import { RpaAreaComponent } from "./highlights-areas/rpa-area/rpa-area.component";
import { SoftwareAreaComponent } from "./highlights-areas/software-area/software-area.component";
import { AutomotiveIndComponent } from "./highlights-industries/automotive-ind/automotive-ind.component";
import { BankingIndComponent } from "./highlights-industries/banking-ind/banking-ind.component";
import { InsuranceIndComponent } from "./highlights-industries/insurance-ind/insurance-ind.component";
import { LogisticsIndComponent } from "./highlights-industries/logistics-ind/logistics-ind.component";
import { PharmaIndComponent } from "./highlights-industries/pharma-ind/pharma-ind.component";
import { RetailIndComponent } from "./highlights-industries/retail-ind/retail-ind.component";
import { TotalHighlightsComponent } from "./total-highlights/total-highlights.component";
import { TestemonsComponent } from "./testemons/testemons.component";
import { StarterPackSectionComponent } from "./footers-areas/starter-pack/starter-pack.component";
import { BulletsComponent } from "./bullets/bullets.component";

@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		NouisliderModule,
		TagInputModule,
		RouterModule,
		JwBootstrapSwitchNg2Module,
		AngularMultiSelectModule,
		FormsModule,
		NgxGalleryModule,
		ImageUploadModule,
		RouterModule,
	],
	declarations: [
		ComponentsComponent,
		BasicelementsComponent,
		NavigationComponent,
		TypographyComponent,
		NucleoiconsComponent,
		NotificationComponent,
		NgbdModalBasic,
		PrefooterAreasComponent,
		FooterMainComponent,
		FootersAreasComponent,
		TablesAreasComponent,
		CardsAreasComponent,
		ModalHighComponent,
		HighlightsStaffComponent,
		ClientsComponent,
		HighlightsStaffIndComponent,
		SlideshowComponent,
		AnalyticsAreaComponent,
		RpaAreaComponent,
		SoftwareAreaComponent,
		AutomotiveIndComponent,
		BankingIndComponent,
		InsuranceIndComponent,
		LogisticsIndComponent,
		PharmaIndComponent,
		RetailIndComponent,
		TotalHighlightsComponent,
		TestemonsComponent,
		FooterMainComponent,
		FootersAreasComponent,
		StarterPackSectionComponent
	],
	exports: [
		ComponentsComponent,
		ModalHighComponent,
		HighlightsStaffComponent,
		HighlightsStaffIndComponent,
		AnalyticsAreaComponent,
		ClientsComponent,
		SlideshowComponent,
		TotalHighlightsComponent,
		FooterMainComponent,
		FootersAreasComponent,
		TestemonsComponent,
		RpaAreaComponent,
		SoftwareAreaComponent,
		AutomotiveIndComponent,
		BankingIndComponent, 
		InsuranceIndComponent,
		RetailIndComponent,
		PharmaIndComponent,
		LogisticsIndComponent,
		BasicelementsComponent,
		CardsAreasComponent,
		NgbdModalBasic,
		StarterPackSectionComponent
	],
})
export class ComponentsModule {}
