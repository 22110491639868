import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import biJson from '../../../assets/bi.json'

@Component({
  selector: 'app-starter-pack',
  templateUrl: './starter-pack.component.html',
  styleUrls: ['./starter-pack.component.scss']
})
export class StarterPackComponent implements OnInit {
	bi: any[] = biJson;  

  private fragment: string;

  constructor(private route: ActivatedRoute){}
 
  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    try { 
      if(this.fragment && document.querySelector('#' + this.fragment)){
        document.querySelector('#' + this.fragment).scrollIntoView();
      }
    } catch (e) {}
  }

  
}
