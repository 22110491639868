<div class="section section-pre-footer" data-background-color="gray">
    <div id="pre-footer-areas">
        <div class="container">
            <div class="title">
                <h3>Pre-Footer Areas</h3>
            </div>
        </div>
        <!--     *********    SIMPLE SOCIAL LINE     *********      -->
        <div class="social-line social-line-white text-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <h4 class="title">Thank you for your support!</h4>
                    </div>
                    <a href="#pablo" class="btn btn-twitter btn-round">
                        <i class="fa fa-twitter"></i> Twitter · 2.5k
                    </a>
                    <a href="#pablo" class="btn btn-facebook btn-round">
                        <i class="fa fa-facebook-square"></i> Facebook · 3.2k
                    </a>
                    <a href="#pablo" class="btn btn-google btn-round">
                        <i class="fa fa-google-plus"></i> Google · 1.2k
                    </a>
                    <a href="#pablo" class="btn btn-dribbble btn-round">
                        <i class="fa fa-dribbble"></i> Dribbble · 1.8k
                    </a>
                </div>
            </div>
        </div>
        <!--     *********   SIMPLE SOCIAL LINE     *********      -->
        <br>
        <br>
        <!--     *********    SIMPLE SOCIAL LINE     *********      -->
        <div class="social-line social-line-big-icons social-line-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-neutral btn-icon btn-twitter btn-footer">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-neutral btn-icon btn-facebook btn-footer">
                            <i class="fa fa-facebook-square"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-neutral btn-icon btn-google btn-footer">
                            <i class="fa fa-google-plus"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-neutral btn-icon btn-dribbble btn-footer">
                            <i class="fa fa-dribbble"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-neutral btn-icon btn-youtube btn-footer">
                            <i class="fa fa-youtube-play"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-neutral btn-icon btn-instagram btn-footer">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********   SIMPLE SOCIAL LINE     *********      -->
        <br>
        <br>
        <!--     *********    SIMPLE BLACK SOCIAL LINE     *********      -->
        <div class="social-line social-line-big-icons social-line-black">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-simple btn-icon btn-footer">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-simple btn-icon btn-footer">
                            <i class="fa fa-facebook-square"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-simple btn-icon btn-footer">
                            <i class="fa fa-google-plus"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-simple btn-icon btn-footer">
                            <i class="fa fa-dribbble"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-simple btn-icon btn-footer">
                            <i class="fa fa-youtube-play"></i>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a href="#pablo" class="btn btn-simple btn-icon btn-footer">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>
        <!--     *********   SIMPLE BLACK SOCIAL LINE     *********      -->
        <br>
        <br>
        <!--     *********    SIMPLE SUBSCRIBE LINE     *********      -->
        <div class="subscribe-line subscribe-line-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="title">Get Tips &amp; Tricks every Week!</h4>
                        <p class="description">
                            Join our newsletter and get news in your inbox every week! We hate spam too, so no worries about this.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-plain card-form-horizontal">
                            <div class="card-content">
                                <form method="" action="">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                                              <div class="input-group-prepend">
                                                  <span class="input-group-text"><i class="now-ui-icons ui-1_email-85"></i></span>
                                              </div>
                                                <input type="email" class="form-control" placeholder="Your Email..."   (focus)="focus=true" (blur)="focus=false">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <button type="button" class="btn btn-primary btn-round btn-block">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********   SIMPLE SUBSCRIBE LINE     *********      -->
        <br>
        <br>
        <!--     *********    IMAGE SUBSCRIBE LINE     *********      -->
        <div class="subscribe-line subscribe-line-image" style="background-image: url('assets/img/bg7.jpg');">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto mr-auto">
                        <div class="text-center">
                            <h4 class="title">Subscribe to our Newsletter</h4>
                            <p class="description">
                                Join our newsletter and get news in your inbox every week! We hate spam too, so no worries about this.
                            </p>
                        </div>
                        <div class="card card-raised card-form-horizontal">
                            <div class="card-body">
                                <form method="" action="">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <div class="input-group"   [ngClass]="{'input-group-focus':focus1===true}">
                                              <div class="input-group-prepend">
                                                  <span class="input-group-text">  <i class="now-ui-icons ui-1_email-85"></i></span>
                                              </div>
                                                <input type="email" class="form-control" placeholder="Your Email..." (focus)="focus1=true" (blur)="focus1=false">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <button type="button" class="btn btn-primary btn-round btn-block">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--     *********   IMAGE SUBSCRIBE LINE     *********      -->
    </div>
</div>
