import { Component, OnInit } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-software-area",
	templateUrl: "./software-area.component.html",
	styleUrls: ["./software-area.component.scss"],
})
export class SoftwareAreaComponent implements OnInit {
	softwareProjs: Project[] = [];
	focus: boolean = false;

	constructor(private projectsService: ProjectsService) {}

	ngOnInit() {
		this.getProjects();
	}

	getProjects(): void {
		this.softwareProjs = this.projectsService.getSoftware();
	}

	search(keywords : string = null){
		console.log(keywords);

		if(keywords == null || keywords == ''){
			this.getProjects();
			return;
		}

		keywords = keywords.toLowerCase();
		this.softwareProjs = this.projectsService.getSoftware().filter(x=> {
			return x.area.toLowerCase().includes(keywords) ||
			x.description.toLowerCase().includes(keywords) ||
			x.industry.toLowerCase().includes(keywords) ||
			x.location.toLowerCase().includes(keywords) ||
			x.short.toLowerCase().includes(keywords) ||
			x.title.toLowerCase().includes(keywords)
		});
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0];
	}
}
