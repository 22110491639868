<div class="section section-sections" style="background-color:
    rgb(251, 247, 252);">
    <div class="container">
        <div class="col-md-8 ml-auto mr-auto">
            <div class="section-description text-center">
                <h2 class="title">Staff Spotlight</h2>
                <h5 class="description"> These are some of our projects we
                    believe you'll love.</h5>

            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="section-cols">
            <div class="row">
                <div class="col-md-3" *ngFor="let projCut of staffAreasProjs">
                    <div class="card card-background card-raised2"
                        data-background-color style="background-image:
                        url('{{projCut.attach.at(0)}}')">

                        <div class="info">
                            <div class="description">
                                <h4 class="info-title">{{projCut.title}}</h4>
                                <p>{{projCut.short}}</p>
                                <ngbd-modal-high-component [proj]="projCut"></ngbd-modal-high-component>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container" style="display: flex; justify-content: center;"> 
        <a [routerLink]="['/areas']" fragment="analytics-area" class="btn
            btn-info ">Analytics BI & ML</a>
        <a [routerLink]="['/areas']" fragment="rpa-area" class="btn
            btn-info ">Automation and RPA</a>
        <a [routerLink]="['/areas']" fragment="software-area" class="btn
            btn-info ">Software</a>
        <a [routerLink]="['/areas']" fragment="ml-area" class="btn
            btn-info ">DataScience & ML</a>
    </div>
</div>