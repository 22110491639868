<div class="wrapper">

    <div class="page-header clear-filter">
        <div class="rellax-header rellax-header-sky" data-rellax-speed="-8">
            <div class="page-header-image" style="background-color:#e52449">
            </div>
        </div>
        <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
            <div class="page-header-image page-header-city" style="background-image:url('assets/img/presentation-page/home2.png')">
            </div>
        </div>
        <div class="rellax-text-container rellax-text">
            <h1 class="h1-seo" data-rellax-speed="-2">Digital Portfolio</h1>
            <div class="pro">brighten</div>
        </div>
        <h3 class="h3-description rellax-text" data-rellax-speed="-1"></h3>
    </div>

    <div class="section section-basic-components" style="background-color:#f6f6f6 !important; padding: 60px 0;">
        <div class="container">
            <div class="row" style="align-items: center;">
                <div class="col-lg-5 col-md-7 ml-auto mr-auto">
                    <h2 class="title" style="margin-top:0px;padding-top:50px;">We are <span style="color:#e52449;">brighten digital</span></h2>
                    <h6 class="category" style="margin-left:5px">Get to know us!</h6>
                    <h5 class="description" style="color:#6a6a6a !important;">
                        We partner with our clients to help them simplify their
                        business and solve their real-life challenges.</h5>
                    <h5 class="description" style="color:#6a6a6a !important;">
                        We work side by side with our clients, in a true
                        partnership spirit, aiming for business results
                        improvement and successful projects.
                    </h5>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="image-container">
                        <img class="components-macbook" style="border-radius:5px;"
                            src="assets/img/presentation-page/present.jpg"
                            alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-total-highlights></app-total-highlights>

    <!-- 
    <app-testemons></app-testemons>
    -->

    <app-cards-areas></app-cards-areas>
    <app-footer-main></app-footer-main>
     
</div>
