<div class="wrapper">
    <div class="features-4">
        <h2 class="title">Software Engineering portfolio</h2>
        <div class="container">
            <app-software-area id="software-area"></app-software-area>
        </div>
    </div>

    <app-footer-main></app-footer-main>
</div>
