<div class="section section-navbars">
    <div class="container" id="menu-dropdown">
        <div class="row">
            <div class="col-md-6">
                <h4>Menu</h4>
                <nav class="navbar navbar-expand-lg bg-primary">
                    <div class="container">
                        <a class="navbar-brand" href="#">Menu</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="example-navbar" data-nav-image="assets/img/blurred-image-1.jpg">
                            <ul class="navbar-nav">
                                <li class="nav-item active">
                                    <a class="nav-link" href="#">
                                        <p>Link</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#">
                                        <p>Link</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                  <div ngbDropdown class="dropdown">
                                      <a class="nav-link dropdown-toggle" ngbDropdownToggle>
                                        <p>
                                            Dropdown
                                        </p>
                                      </a>
                                      <div ngbDropdownMenu class="dropdown-menu">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                      </div>
                                  </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-md-6">
                <h4>Menu with Icons</h4>
                <nav class="navbar navbar-expand-lg bg-info">
                    <div class="container">
                        <a class="navbar-brand" href="#">Icons</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-icons" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="example-navbar-icons">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="#pablo"><i class="now-ui-icons ui-1_send" aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#pablo"><i class="now-ui-icons users_single-02" aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item">
                                    <div ngbDropdown class="dropdown">
                                        <a class="nav-link dropdown-toggle" ngbDropdownToggle>
                                            <i class="now-ui-icons ui-1_settings-gear-63"></i>
                                        </a>
                                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                                            <div class="dropdown-header">Dropdown header</div>
                                            <a class="dropdown-item">Action</a>
                                            <a class="dropdown-item">Another action</a>
                                            <a class="dropdown-item">Something else here</a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item">Separated link</a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item">Another separated link</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <h4>Navigation</h4>
    </div>
    <div id="navbar">
        <div class="navigation-example" style="background-image:url('assets/img/bg7.jpg')">
            <!-- Navbar Primary  -->
            <nav class="navbar navbar-expand-lg bg-primary">
                <div class="container">
                    <div class="navbar-translate">
                        <a class="navbar-brand" href="#pablo">Primary color</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-primary" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-primary">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#pablo">
                                    <i class="now-ui-icons objects_globe"></i>
                                    <p>Discover</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="now-ui-icons users_circle-08"></i>
                                    <p>Profile</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="now-ui-icons ui-1_settings-gear-63"></i>
                                    <p>Settings</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar Primary -->
            <!-- Navbar Info -->
            <nav class="navbar navbar-expand-lg bg-info">
                <div class="container">
                    <div class="navbar-translate">
                        <a class="navbar-brand" href="#pablo">Info Color</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-info" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-info">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#pablo">
                                    <p>Discover</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <p>Profile</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <p>Settings</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar Info -->
            <!-- Navbar Success -->
            <nav class="navbar navbar-expand-lg bg-success">
                <div class="container">
                    <div class="navbar-translate">
                        <a class="navbar-brand" href="#">Success Color</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-success" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-success">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#pablo">
                                    <i class="now-ui-icons objects_globe"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="now-ui-icons users_circle-08"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="now-ui-icons ui-1_settings-gear-63"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar Success -->
            <!-- Navbar Warning -->
            <nav class="navbar navbar-expand-lg bg-warning">
                <div class="container">
                    <div class="navbar-translate">
                        <a class="navbar-brand" href="#pablo">Warning Color</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-warning" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-warning">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-facebook-square"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-google-plus"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar Warning -->
            <!-- Navbar Danger -->
            <nav class="navbar navbar-expand-lg bg-danger">
                <div class="container">
                    <div class="navbar-translate">
                        <a class="navbar-brand" href="#pablo">Danger Color</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-danger" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-danger">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-facebook-square"></i>
                                    <p>Share</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-twitter"></i>
                                    <p>Tweet</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-pinterest"></i>
                                    <p>Pin</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar Danger -->
            <!-- Navbar Transparent -->
            <nav class="navbar navbar-expand-lg navbar-transparent">
                <div class="container">
                    <div class="navbar-translate">
                        <a class="navbar-brand" href="#pablo">Transparent</a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-transparent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <div class="collapse navbar-collapse" id="example-navbar-transparent">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-facebook-square"></i>
                                    <p>Facebook</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-twitter"></i>
                                    <p>Twitter</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#pablo">
                                    <i class="fa fa-instagram"></i>
                                    <p>Instagram</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar Transparent-->
        </div>
    </div>
</div>
