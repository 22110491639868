<div class="cd-section" id="features">

    <div class="features-3">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto">
                    <h2 class="title">Our Values</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="info info-hover">
                        <div class="icon icon-value-red icon-circle">
                            <img src="assets/img/about/value-shirt.png" />
                        </div>
                        <h4 class="info-title">wear the shirt</h4>
                        <p class="description">
                            <p>• Live by brighten values, always deliver above the expected quality.</p>
                            <p>• Work every day to be even more of a 'brighten' consultant.</p>
                            <p>• Customer satisfaction is always first.</p>
                            <p></p>
                       </div>
                    </div>
                    <div class="col-md-4">
                        <div class="info info-hover">
                            <div class="icon icon-value-red icon-circle">
                                <img src="assets/img/about/value-challenge.png"
                                    />
                            </div>
                            <h4 class="info-title">challenge yourself every day</h4>
                            <p class="description values"><p>
                                    • Always look for new challenges and new
                                    responsibilities.</p><p>
                                    • Be more comfortable with change than with
                                    the status quo.</p><p>
                                    • Get out of your comfort zone and learn
                                    more: your goal is
                                    personal and professional growth.</p><p>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="info info-hover">
                                <div class="icon icon-value-red icon-circle">
                                    <img src="assets/img/about/value-add.png"
                                        />
                                </div>
                                <h4 class="info-title">always add value</h4>
                                <p class="description values"><p>
                                        • Do not just execute; think.</p><p>
                                        • Seek to add value to everything you
                                        do.</p><p>
                                        • Quality and value delivered to the
                                        customer is the number one objective,
                                        even if
                                        it requires one more “extra mile”.</p><p>
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="info info-hover">
                                    <div class="icon icon-value-red
                                        icon-circle">
                                        <img
                                            src="assets/img/about/value-help.png"
                                            />
                                    </div>
                                    <h4 class="info-title">help first</h4>
                                    <p class="description values"><p>
                                            • Help first, then receive.</p><p>
                                            • Volunteer for the pleasure of
                                            helping others, and think about how
                                            you can help them first.</p><p>
                                            • Genuinely enjoy helping your
                                            colleagues, customers and partners..</p><p>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="info info-hover">
                                        <div class="icon icon-value-red
                                            icon-circle">
                                            <img
                                                src="assets/img/about/value-simplify.png"
                                                />
                                        </div>
                                        <h4 class="info-title">simplify</h4>
                                        <p class="description values"><p>
                                                • Proactively identify
                                                everything which can be
                                                simplified inside and outside
                                                the home.</p><p>
                                                • Believe there's always a
                                                solution to every challenge and
                                                share it.</p><p>
                                                • Take on the challenges as your
                                                own and actively participate in
                                                building solutions.</p><p>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="info info-hover">
                                            <div class="icon icon-value-red
                                                icon-circle">
                                                <img
                                                    src="assets/img/about/value-say.png"
                                                    />
                                            </div>
                                            <h4 class="info-title">do as you say</h4>
                                            <p class="description values"><p>
                                                    • Deliver what you committed
                                                    to, with quality and within
                                                    deadlines.</p><p>
                                                    • Always try to do your best
                                                    and even more, but promise
                                                    only what you can deliver.</p><p>
                                                    • Always take responsibility
                                                    for yourself and your people
                                                    - the most important thing
                                                    is to learn from our
                                                    mistakes.</p><p>
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
    </div>

    <div class="features-6" style="padding-top:0px;">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Our methodology</h2>
                    <h4 class="description">We have been delivering design,
                        architecture, development and deployment of secured solutions that
                        offer carefully curated data and critical business indicators for the organization.
                    </h4>
                </div>
            </div>
            <div class="row" style="display:block;text-align: center;">
                <div class="col-md-5" style="display:inline-block;vertical-align: top;">
                    <div class="info info-horizontal">
                        <div class="icon icon-value-red">
                            <i class="now-ui-icons business_briefcase-24 "></i>
                        </div>
                        <div class="description">
                            <h5 class="info-title">Business design</h5>
                            <ul><li>Focus on business needs</li>
                                <li>Industry knowledge</li>
                                <li>Functional knowledge</li>
                                <li>From KPI to data source</li>
                            </ul>
                        </div>
                    </div>
                    <div class="info info-horizontal">
                        <div class="icon icon-value-blue">
                            <i class="now-ui-icons design_vector"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Technical design</h4>
                            <ul>
                                <li>Focus on performance and automation</li>
                                <li>Technology, automation and integration scalability</li>
                                <li>Middleware technology, optimize data flow</li>
                                <li>From performance to requirements</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-5" style="display:inline-block;vertical-align: top;">
                    <div class="info info-horizontal">
                        <div class="icon icon-value-yellow">
                            <i class="now-ui-icons design-2_ruler-pencil"></i>
                        </div>
                        <div class="description">
                            <h5 class="info-title">Application design</h5>
                            <ul>
                                <li>Focus on usability and user-friendliness</li>
                                <li>Standardization/common design</li>
                                <li>Minimizing learning curve</li>
                                <li>Modernization and scalability</li>
                            </ul>
                        </div>
                    </div>
                    <div class="info info-horizontal">
                        <div class="icon icon-value-red">
                            <i class="now-ui-icons files_single-copy-04"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Data design</h4>
                            <ul>
                                <li>From business needs to the data model</li>
                                <li>Focus on data required to respond to business
                                    needs</li>
                                <li>Single source of truth</li>
                                <li>Data mapping/data cleansing</li>
                            </ul>>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

</div>
