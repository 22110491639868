import { Component, OnInit } from '@angular/core';
import { Project } from 'app/interfaces/project';
import { ProjectsService } from 'app/services/projects.service';

@Component({
  selector: 'app-highlights-staff',
  templateUrl: './highlights-staff.component.html',
  styleUrls: ['./highlights-staff.component.scss']
})
export class HighlightsStaffComponent implements OnInit {

  projs: Project[] = [];
  staffAreasProjs: Project[] = [];

  constructor(private projectsService: ProjectsService,) { }

  ngOnInit() {
    
    this.getProjects();
  }


  getProjects(): void {
    this.projs = this.projectsService.getProjects();
    this.staffAreasProjs = this.projectsService.getStaffAreas();
  }
}
