<nav class="navbar navbar-expand-lg fixed-top" color-on-scroll="500">
    <div class="container">
        <div class="navbar-translate">
            <a class="navbar-brand" [routerLink]="['/home']" placement="bottom"
                ngbTooltip="Contact us. We are here to help">
                Brighten Digital
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarToggler" aria-controls="navbarTogglerDemo02"
                aria-expanded="false" aria-label="Toggle navigation"
                (click)="sidebarToggle()">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
            </button>
        </div>
        <div class="collapse navbar-collapse"
            data-nav-image="assets/img/presentation-page/nav.png"
            data-color="gray">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a ngbDropdown class="nav-item dropdown">
                        <div class="nav-link dropdown-toggle" ngbDropdownToggle>
                            <i class="now-ui-icons ui-2_settings-90" aria-hidden="true"></i>
                            <p>Solutions</p>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" [routerLink]="['/playground']"><i class="now-ui-icons business_chart-bar-32 "></i> BI Starter-pack</a>
                            <a class="dropdown-item" [routerLink]="['/plab']"><i class="now-ui-icons ui-2_settings-90"></i> Procurement Portal</a>
                            <a class="dropdown-item" [routerLink]="['/trading']"><i class="now-ui-icons text_align-left"></i> Trading Portal</a>
                            <a class="dropdown-item" [routerLink]="['/shopfloor']"><i class="now-ui-icons text_align-left"></i> Shopfloor</a>
                            <a class="dropdown-item" [routerLink]="['/sales']"><i class="now-ui-icons text_align-left"></i> AppMySales</a>
                            <a class="dropdown-item" [routerLink]="['/chatbot']"><i class="now-ui-icons business_chart-bar-32 "></i> AppMyChatbot</a>
                        </div>
                    </a>
                </li>

                <li class="nav-item">
                    <a ngbDropdown class="nav-item dropdown">
                        <div class="nav-link dropdown-toggle" ngbDropdownToggle>
                            <i class="now-ui-icons ui-2_settings-90" aria-hidden="true"></i>
                            <p>Technology</p>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" [routerLink]="['/tech/analytics']"><i class="now-ui-icons business_chart-bar-32 "></i> BI Analytics</a>
                            <a class="dropdown-item" [routerLink]="['/tech/rpa']"><i class="now-ui-icons ui-2_settings-90"></i> Automation & RPA</a>
                            <a class="dropdown-item" [routerLink]="['/tech/software']"><i class="now-ui-icons text_align-left"></i> Software Engineering</a>
                            <a class="dropdown-item" [routerLink]="['/tech/ml']"><i class="now-ui-icons business_chart-bar-32 "></i> DataScience & ML</a>
                        </div>
                    </a>
                </li>

                <li class="nav-item">
                    <a ngbDropdown class="nav-item dropdown">
                        <div class="nav-link dropdown-toggle" ngbDropdownToggle>
                            <i class="now-ui-icons location_world" aria-hidden="true"></i>
                            <p>Industry</p>
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" [routerLink]="['/industries']" ><i class="now-ui-icons shopping_delivery-fast"></i> Automotive</a>
                            <a class="dropdown-item" [routerLink]="['/industries']" fragment="ph-ind"><i class="now-ui-icons health_ambulance"></i> Pharmaceuticals</a>
                            <a class="dropdown-item" [routerLink]="['/industries']" fragment="ret-ind"><i class="now-ui-icons shopping_basket"></i> Retail</a>
                            <a class="dropdown-item" [routerLink]="['/industries']" fragment="log-ind"><i class="now-ui-icons design_app"></i> Logistics</a>
                            <a class="dropdown-item" [routerLink]="['/industries']" fragment="bank-ind"><i class="now-ui-icons business_money-coins"></i> Banking</a>
                            <a class="dropdown-item" [routerLink]="['/industries']" fragment="ins-ind"><i class="now-ui-icons business_badge "></i> Insurance</a>
                        </div>
                    </a>
                </li>

            </ul>
        </div>
    </div>
</nav>
