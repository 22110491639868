import { Component, OnInit } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-rpa-area",
	templateUrl: "./rpa-area.component.html",
	styleUrls: ["./rpa-area.component.scss"],
})
export class RpaAreaComponent implements OnInit {
	rpaProjs: Project[] = [];
	focus : boolean = false;

	constructor(
		private projectsService: ProjectsService
	) {}


	ngOnInit() {
		this.getProjects();
	}

	getProjects(): void {
		this.rpaProjs = this.projectsService.getRPA();
	}

	search(keywords : string = null){
		if(keywords == null || keywords == ''){
			this.getProjects();
			return;
		}

		keywords = keywords.toLowerCase();
		this.rpaProjs = this.projectsService.getRPA().filter(x=> {
			return x.area.toLowerCase().includes(keywords) ||
				x.description.toLowerCase().includes(keywords) ||
				x.industry.toLowerCase().includes(keywords) ||
				x.location.toLowerCase().includes(keywords) ||
				x.short.toLowerCase().includes(keywords) ||
				x.title.toLowerCase().includes(keywords)
		});
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0]
	}
}
