<!--logistics projs-->
<div class="row col-md-12 ml-auto mr-auto text-center group-parent" *ngIf="logisticsProjs?.length > 0">
    <a id="logistics-ind" class="group-title title">Logistics</a>
</div>
 

<div class="cards" data-background-color="gray" *ngIf="logisticsProjs?.length > 0">
    <div class="row">
        <div class="col-md-6 col-lg-12" style="padding:0px">
            <div class="card card-blog col-sm-3" style="padding:0px;margin-right:15px" *ngFor="let proj of logisticsProjs">
                <div class="card-image">
                    <img class="img rounded" src="{{ getImage(proj) }}">
                </div>
                <div class="card-body">
                    <h6 class="category text-primary" style="font-size:0.9em;"><i class="now-ui-icons tech_watch-time"></i> {{proj.area}}</h6>
                    <h5 class="card-title">
                        {{proj.title}}
                    </h5>
                    <p class="card-description">
                        {{proj.short}}
                    </p>
                    <div class="card-footer">
                        <ngbd-modal-high-component [proj]="proj"></ngbd-modal-high-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>