import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.scss']
})
export class FooterMainComponent implements OnInit {
    date : Date = new Date();
    focus;
    focus1;
    focus2;
    focus3;
    focus4;
  
    constructor() { }

    ngOnInit() {
      
    }

}
