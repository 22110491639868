 <div style="padding-bottom:80px;margin:80px;background: #fff;">
    <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="padding-bottom: 20px;padding-top:0px;margin-top: 25px;margin-bottom: 15px;">Some of our clients</h2>
        </div>
    </div>

    <div class="row">
        <div style="width:100%">

        <div class="col-md-12" style="background: white;padding: 15px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
            <ngb-carousel [interval]="7000" [wrap]="true" [pauseOnFocus]="false" [pauseOnHover]="false" [keyboard]="true" [animation]="true">
                <ng-template ngbSlide>
                    <div class="row" style="align-items: center;">
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-vodafone.png"
                                alt="Vodafone" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-saint-gobain.jpg"
                                alt="SAINT-GOBAIN" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-schnellecke.png"
                                alt="Schnellecke" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-ardagh.tif.png"
                                alt="ArdaghGroup" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-thy.jpg"
                                alt="thyssenkrupp" />
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="row" style="align-items: center;">
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-renova.png"
                                alt="Renova" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-visteon.png"
                                alt="Visteon" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-novartis.jpg"
                                alt="Novartis" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img
                                src="assets/img/presentation-page/client-generis.png"
                                alt="Generis" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-phone-house.jpg"
                                alt="Phone House" />
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="row" style="align-items: center;">
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-lusiadas.png"
                                alt="Lusíadas" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-montellano.png"
                                alt="Montellano" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-nordex.png"
                                alt="Nordex Acciona" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-nos.png"
                                alt="NOS" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-jchaves.jpg"
                                alt="Joaquim Chaves" />
                        </div>
                        
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="row" style="align-items: center;">
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-om-pharma.jpg"
                                alt="OM Pharma" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-perfumes.png"
                                alt="Perfumes & Companhia" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-plmj.png"
                                alt="PLMJ" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-rbc.png"
                                alt="RBC" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-register.png"
                                alt="Register Group" />
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide> 
                    <div class="row" style="align-items: center;">
        
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-luis-simoes.jpg"
                                alt="Luís Simões" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-siemens.png"
                                alt="Siemens Gamesa" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-axa.png"
                                alt="AXA" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-tabaqueira.png"
                                alt="Tabaqueira" />
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="row" style="align-items: center;">
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-trivium.jpg"
                                alt="TRIVIUM Packaging" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-viseeon.png"
                                alt="viseeon" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-360.png"
                                alt="360 Imprimir" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/client-konecranes.png"
                                alt="Konecranes" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-telcabo.jpg"
                                alt="Telcabo" />
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide> 
                    <div class="row" style="align-items: center;">
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-akzo-nobel.png"
                                alt="Akzo Nobel" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-blades.png"
                                alt="Blades" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-cellnex.jpg"
                                alt="Cellnex" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-cosec.png"
                                alt="Cosec" />
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="row">
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-engie.png"
                                alt="Engie" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-european-parliament.png"
                                alt="European Parliament" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-jap.jpg"
                                alt="JAP" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-montepio.jpg"
                                alt="Montepio" />
                        </div>
                        <div class="col-md-2 ml-auto">
                            <img src="assets/img/presentation-page/x-client-vasp.jpg"
                                alt="Vasp" />
                        </div>
                    </div>
                </ng-template>

            </ngb-carousel>

        </div>

        </div>
    </div>
</div>
