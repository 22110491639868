import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-starter-pack-section',
  templateUrl: './starter-pack.component.html',
  styleUrls: ['./starter-pack.component.scss']
})
export class StarterPackSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
