<div class="wrapper">
    <div class="features-4">
        <h2 class="title">BI Analytics portfolio</h2>

        <div class="container">
            <app-analytics-area id="analytics-area"></app-analytics-area>
        </div>
    </div>

    <app-starter-pack-section></app-starter-pack-section>
    <app-footer-main></app-footer-main>
</div>
