<!-- <div id="cards" class="section section-cards" data-background-color="gray">
    <div class="cards">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-4">
                    <div class="card" data-background-color="red">
                        <div class="card-body">
                            <h6 class="category-social">
                                <i class="fa fa-fire"></i> Trending
                            </h6>
                            <p class="card-description">
                                "The supreme art of war is to subdue the enemy without fighting."
                            </p>
                            <div class="card-footer">
                                <div class="author">
                                    <img src="assets/img/julie.jpg" alt="..." class="avatar img-raised">
                                    <span>Susan B. Anthony</span>
                                </div>
                                <div class="stats stats-right">
                                    <i class="now-ui-icons ui-2_favourite-28"></i> 10.4K ·
                                    <i class="now-ui-icons files_single-copy-04"></i> 425
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-blog">
                        <div class="card-image">
                            <img class="img rounded" src="assets/img/project13.jpg">
                        </div>
                        <div class="card-body">
                            <h6 class="category text-warning">
                                <i class="now-ui-icons business_bulb-63"></i> Focus
                            </h6>
                            <h5 class="card-title">
                                <a href="#nuk">Stay Focused: Train Your Brain</a>
                            </h5>
                            <p class="card-description">
                                Our brains are finely attuned to distraction, so today's digital environment makes it especially hard to focus...
                            </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <h6 class="category text-danger">
                                <i class="now-ui-icons media-2_sound-wave"></i> Trending
                            </h6>
                            <h5 class="card-title">
                                <a href="#pablo">Here Be Dragons</a>
                            </h5>
                            <p class="card-description">
                                An immersive production studio focused on virtual reality content, has closed a $10 million Series A round led by Discovery Communications
                            </p>
                            <div class="card-footer">
                                <div class="author">
                                    <img src="assets/img/olivia.jpg" alt="..." class="avatar img-raised">
                                    <span>Lord Alex</span>
                                </div>
                                <div class="stats stats-right">
                                    <i class="now-ui-icons ui-2_favourite-28"></i> 342 ·
                                    <i class="now-ui-icons files_single-copy-04"></i> 45
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card" data-background-color="black">
                        <div class="card-body content-danger">
                            <h6 class="category-social">
                                <i class="fa fa-apple"></i> New Apps
                            </h6>
                            <h4 class="card-title">
                                <a href="#nuk">FiftyThree Files For Paper</a>
                            </h4>
                            <p class="card-description">
                                Yesterday, as Facebook launched its news reader app Paper, design-focused startup FiftyThree called out Facebook publicly for using their brand name...
                            </p>
                            <div class="card-footer text-center">
                                <a href="#pablo" class="btn btn-default btn-round">Read Article</a>
                            </div>
                        </div>
                    </div>
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="#pablo">
                                <img class="img rounded" src="assets/img/card-blog2.jpg">
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="category text-primary">Features</h6>
                            <h5 class="card-title">
                                That’s One Way To Ditch Your Passenger
                            </h5>
                            <p class="card-description">
                                As near as we can tell, this guy must have thought he was going over backwards and tapped the rear break to bring the nose down...
                            </p>
                            <div class="card-footer">
                                <div class="author">
                                    <img src="assets/img/julie.jpg" alt="..." class="avatar img-raised">
                                    <span>Mike John</span>
                                </div>
                                <div class="stats stats-right">
                                    <i class="now-ui-icons tech_watch-time"></i> 5 min read
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" data-background-color="blue">
                        <div class="card-body">
                            <h6 class="category-social">
                                <i class="fa fa-twitter"></i> Twitter
                            </h6>
                            <p>
                                "You Don't Have to Sacrifice Joy to Build a Fabulous Business and Life"
                            </p>
                            <div class="card-footer">
                                <div class="author">
                                    <img src="assets/img/james.jpg" alt="..." class="avatar img-raised">
                                    <span>Tania Andrew</span>
                                </div>
                                <div class="stats stats-right">
                                    <i class="now-ui-icons ui-2_favourite-28"></i> 2.4K ·
                                    <i class="now-ui-icons files_single-copy-04"></i> 45
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 offset-md-2 col-lg-4 offset-lg-0">
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="#pablo">
                                <img class="img rounded" src="assets/img/examples/card-blog6.jpg">
                            </a>
                        </div>
                        <div class="card-body text-center">
                            <h6 class="category text-danger">
                                <i class="now-ui-icons media-2_sound-wave"></i> Business
                            </h6>
                            <h5 class="card-title">
                                Axel Springer Spends $343M To Buy Business Insider
                            </h5>
                            <p class="card-description">
                                German media giant Axel Springer has announced it’s acquiring online business news publication Business Inside...
                            </p>
                            <div class="card-footer">
                                <a href="#nuk" class="btn btn-primary">Read Article</a>
                            </div>
                        </div>
                    </div>
                    <div class="card card-blog">
                        <div class="card-image">
                            <img class="img rounded" src="assets/img/card-blog3.jpg">
                        </div>
                        <div class="card-body">
                            <h6 class="category text-warning">
                                <i class="now-ui-icons media-1_camera-compact"></i> Photo
                            </h6>
                            <h5 class="card-title">
                                <a href="#pablo">Indispensible to nature photography: the hide</a>
                            </h5>
                            <div class="card-footer">
                                <div class="stats stats-right">
                                    <i class="now-ui-icons ui-2_favourite-28"></i> 342 ·
                                    <i class="now-ui-icons files_single-copy-04"></i> 45
                                </div>
                                <div class="author">
                                    <img src="assets/img/james.jpg" alt="..." class="avatar img-raised">
                                    <span>Devin Coldewey</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-starter-pack-section></app-starter-pack-section>