
<div class="cd-section" id="contact-us">
    <!--     *********    CONTACT US 1     *********      -->
    <div class="contactus-1 section-image" style="background-image: url('assets/img/about/contact.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <h2 class="title">get in touch</h2>
                    <h4 class="description">You need more information? Check what our customers are saying about us. They are very happy with their choice.</h4>
                    <div class="info info-horizontal">
                        <div class="icon icon-primary">
                            <i class="now-ui-icons location_pin"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Find us at the office</h4>
                            <p class="description">R. Cupertino de Miranda 6 2B
                                <br>  1600-513 Lisboa
                                <br> Portugal
                            </p>
                        </div>
                    </div>
                    <div class="info info-horizontal">
                        <div class="icon icon-primary">
                            <i class="now-ui-icons tech_mobile"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Give us a ring</h4>
                            <p class="description"> +351 212 840 150
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 ml-auto mr-auto" style="display:none">
                    <div class="card card-contact card-raised">
                        <form role="form" id="contact-form" method="post">
                            <div class="card-header text-center">
                                <h4 class="card-title">Contact Us</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6 pr-2">
                                        <label>First name</label>
                                        <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                                          <div class="input-group-prepend">
                                              <span class="input-group-text"><i class="now-ui-icons users_circle-08"></i></span>
                                          </div>
                                            <input type="text" class="form-control" placeholder="First Name..." (focus)="focus=true" (blur)="focus=false">
                                        </div>
                                    </div>
                                    <div class="col-md-6 pl-2">
                                        <div class="form-group">
                                            <label>Last name</label>
                                            <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                                              <div class="input-group-prepend">
                                                  <span class="input-group-text"><i class="now-ui-icons text_caps-small"></i></span>
                                              </div>
                                                <input type="text" placeholder="Last Name..." class="form-control" (focus)="focus1=true" (blur)="focus1=false">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email address</label>
                                    <div class="input-group" [ngClass]="{'input-group-focus':focus2===true}">
                                      <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="now-ui-icons ui-1_email-85"></i></span>
                                      </div>
                                        <input type="email" placeholder="Email Here..." class="form-control" (focus)="focus2=true" (blur)="focus2=false">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Your message</label>
                                    <textarea name="message" class="form-control" id="message" rows="6"></textarea>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                      <div class="form-check">
                                          <label class="form-check-label">
                                              <input class="form-check-input" type="checkbox">
                                              <span class="form-check-sign"></span>
                                              I'm not a robot
                                          </label>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                        <button type="submit" class="btn btn-primary btn-round pull-right">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--     *********    END CONTACT US 1      *********      -->
    
</div>
