import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

  @Input() attachs: any;

  constructor() { }

  ngOnInit(): void {
  }

  multipleAttachs(): boolean {
    return this.attachs.length > 1;
  }

  getDefaultImage(){
    return this.attachs[0].src || this.attachs;
  }
  getImage(a : any){
    return a.src || a;
  }

}
