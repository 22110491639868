<div class="wrapper">
    <div class="section-space"></div>
    <app-headers></app-headers>
    <app-features></app-features>
    <app-blogs></app-blogs>
    <app-teams></app-teams>
    <app-projects></app-projects>
    <app-pricing></app-pricing>
    <app-testimonials></app-testimonials>
    <app-contacts></app-contacts>
</div>
