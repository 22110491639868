import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-retail-ind",
	templateUrl: "./retail-ind.component.html",
	styleUrls: ["./retail-ind.component.scss"],
})
export class RetailIndComponent implements OnInit, OnChanges {
	projs: Project[] = [];
	retailProjs: Project[] = [];

	@Input() keywords : string = null;

	constructor(private projectsService: ProjectsService) {}

	ngOnInit() {
		this.getProjects();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.getProjects();
	}

	getProjects(): void {
		this.retailProjs = this.projectsService.getRetail(this.keywords);
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0];
	}
}
