import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ComponentsComponent } from "./components/components.component";
import { SectionsComponent } from "./sections/sections.component";
import { NucleoiconsComponent } from "./components/nucleoicons/nucleoicons.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./pages/about/about.component";
import { AboutModule } from "./pages/about/about.module";
import { TeamsComponent } from "./sections/teams/teams.component";
import { AreasComponent } from "./pages/areas/areas.component";
import { IndustriesComponent } from "./pages/industries/industries.component";
import { SoftwareComponent } from "./pages/areas/software/software.component";
import { BiComponent } from "./pages/areas/bi/bi.component";
import { RPAComponent } from "./pages/areas/rpa/rpa.component";
import { StarterPackComponent } from "./pages/starter-pack/starter-pack.component";

export const routes: Routes = [
	{ path: "", redirectTo: "home", pathMatch: "full" },
	{ path: "home", component: HomeComponent },
	{ path: "components", component: ComponentsComponent },
	{ path: "about", component: AboutComponent },
	{ path: "tech", component: AreasComponent},
	{ path: "tech/analytics", component: BiComponent},
	{ path: "tech/analytics/starter-pack", component: StarterPackComponent},
	{ path: "playground", component: StarterPackComponent},
	{ path: "tech/bi", component: BiComponent},
	{ path: "tech/software", component: SoftwareComponent},
	{ path: "tech/rpa", component: RPAComponent},
	{ path: "industries", component: IndustriesComponent},
	{ path: "sections", component: SectionsComponent },
	{ path: "nucleoicons", component: NucleoiconsComponent }
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		AboutModule,
		RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled',
		scrollOffset: [0, 64]})
	],
	exports: [],
})
export class AppRoutingModule {}
