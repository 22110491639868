<div class="cd-section" id="headers">
    <!--     *********     HEADER 1      *********      -->
    <div class="header-1">
        <div class="page-header header-filter">
            <div class="page-header-image" style="background-image: url('assets/img/about/index.png');"></div>
            <div class="content-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 ml-auto text-right" style="margin-top: 80px;">
                            <h1 class="title">brighten</h1>
                            <h3 class="title">our mission</h3>
                            <h5>
                                Simplifying your business. Together.<p></p>
                                We help our clients simplify their business.
                            </h5>                        
                            <br />
                            <div class="buttons">
                                <a href="https://twitter.com/brightenpt" class="btn btn-icon btn-link btn-neutral btn-lg">
                                    <i class="fa fa-twitter"></i>
                                </a>
                                <a href="https://pt-br.facebook.com/brightenconsultingpt/" class="btn btn-icon btn-link btn-neutral btn-lg">
                                    <i class="fa fa-facebook-square"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/brighten-consulting/" class="btn btn-icon btn-link btn-neutral btn-lg">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--     *********    END HEADER 1      *********      -->
</div>
