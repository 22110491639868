import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-banking-ind",
	templateUrl: "./banking-ind.component.html",
	styleUrls: ["./banking-ind.component.scss"],
})
export class BankingIndComponent implements OnInit, OnChanges {
	bankingProjs: Project[] = [];

	@Input() keywords : string = null;

	constructor(private projectsService: ProjectsService) {}

	ngOnInit() {
		this.getProjects();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.getProjects();
	}
	
	getProjects(): void {
		this.bankingProjs = this.projectsService.getBanking(this.keywords);
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0];
	}

}
