import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements OnInit {

  private fragment: string;
  focus : boolean = false;
  keywords : string = null;

  constructor(private route: ActivatedRoute){}
 
  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }
  ngAfterViewInit(): void {
    try {
      if(this.fragment && document.querySelector('#' + this.fragment)){
        document.querySelector('#' + this.fragment).scrollIntoView();
      }
    } catch (e) {}
  }

  search(keywords){
    this.keywords = keywords;
  }
  
}
