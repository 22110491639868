<div class="wrapper">
  <div class="page-header clear-filter" filter-color="orange">
    <div class="page-header-image" data-parallax="true" >
    </div>
    <div class="rellax-header rellax-header-sky" data-rellax-speed="-8">
      <div class="page-header-image" style="background-image: url('assets/img/presentation-page/nuk-pro-back-sky.jpg')">
      </div>
    </div>
    <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
      <div class="page-header-image page-header-city"
        style="background-image: url('assets/img/presentation-page/nuk-pro-buildings.png')">
      </div>
    </div>
    <div class="rellax-text-container">
      <h1 class="h1-seo rellax-text" data-rellax-speed="-2">Components</h1>
    </div>
    <h3 class="h3-description rellax-text" data-rellax-speed="-1">A beautiful premium Bootstrap 4 UI Kit.</h3>
    <h6 class="category category-absolute rellax-text" data-rellax-speed="-2">Designed by
      <a href="https://invisionapp.com/" target="_blank">
        <img src="assets/img/invision-white-slim.png" class="invision-logo" />
      </a>. Coded by
      <a href="https://www.brightenconsulting.com" target="_blank">
        <img src="assets/img/creative-tim-white-slim2.png" class="creative-tim-logo" />
      </a>.
    </h6>
  </div>
  <div class="main">
    <div class="section section-images">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="hero-images-container">
              <img src="assets/img/hero-image-1.png" alt="">
            </div>
            <div class="hero-images-container-1">
              <img src="assets/img/hero-image-2.png" alt="">
            </div>
            <div class="hero-images-container-2">
              <img src="assets/img/hero-image-3.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-basicelements></app-basicelements>
    <app-navigation></app-navigation>
    <div class="section section-tabs">
      <div class="container">
        <div class="title">
          <h4>Navigation Tabs</h4>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto col-xl-6 mr-auto">
            <p class="category">Tabs with Icons on Card</p>
            <!-- Nav tabs -->
            <div class="card">
              <div class="nav-tabs-navigation">
                <div class="nav-tabs-wrapper">
                  <ul [(activeId)]="activeTab1" ngbNav #nav1="ngbNav" class="text-center nav-tabs">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>
                        <i class="now-ui-icons objects_umbrella-13"></i> Home
                      </a>
                      <ng-template ngbNavContent>
                        <p>I think that’s a responsibility that I have, to push possibilities, to show people, this is
                          the level that things could be at. So when you get something that has the name Kanye West on
                          it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company
                          that ends up being worth billions of dollars, because I got the answers. I understand culture.
                          I am the nucleus.</p>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>
                        <i class="now-ui-icons shopping_cart-simple"></i> Profile
                      </a>
                      <ng-template ngbNavContent>
                        <p> I will be the leader of a company that ends up being worth billions of dollars, because I
                          got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that
                          I have, to push possibilities, to show people, this is the level that things could be at. I
                          think that’s a responsibility that I have, to push possibilities, to show people, this is the
                          level that things could be at. </p>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                      <a ngbNavLink>
                        <i class="now-ui-icons shopping_shop"></i> Messages
                      </a>
                      <ng-template ngbNavContent>
                        <p>I think that’s a responsibility that I have, to push possibilities, to show people, this is
                          the level that things could be at. So when you get something that has the name Kanye West on
                          it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company
                          that ends up being worth billions of dollars, because I got the answers. I understand culture.
                          I am the nucleus.</p>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                      <a ngbNavLink>
                        <i class="now-ui-icons ui-2_settings-90"></i> Settings
                      </a>
                      <ng-template ngbNavContent>
                        <p>
                          "I will be the leader of a company that ends up being worth billions of dollars, because I got
                          the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I
                          have, to push possibilities, to show people, this is the level that things could be at."
                        </p>
                      </ng-template>
                    </li>
                  </ul>
                  <div [ngbNavOutlet]="nav1" class="mt-2 text-center"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-10 ml-auto col-xl-6 mr-auto">
            <p class="category">Tabs with Background on Card</p>
            <!-- Tabs with Background on Card -->
            <div class="card">
              <div class="nav-tabs-navigation" data-tabs-color="orange">
                <div class="nav-tabs-wrapper">
                  <ul [(activeId)]="activeTab2" ngbNav #nav2="ngbNav" class="text-center nav-tabs">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>Home</a>
                      <ng-template ngbNavContent>
                        <p>I think that’s a responsibility that I have, to push possibilities, to show people, this is
                          the level that things could be at. So when you get something that has the name Kanye West on
                          it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company
                          that ends up being worth billions of dollars, because I got the answers. I understand culture.
                          I am the nucleus.</p>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>Profile</a>
                      <ng-template ngbNavContent>
                        <p> I will be the leader of a company that ends up being worth billions of dollars, because I
                          got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that
                          I have, to push possibilities, to show people, this is the level that things could be at. I
                          think that’s a responsibility that I have, to push possibilities, to show people, this is the
                          level that things could be at. </p>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                      <a ngbNavLink>Messages</a>
                      <ng-template ngbNavContent>
                        <p>I think that’s a responsibility that I have, to push possibilities, to show people, this is
                          the level that things could be at. So when you get something that has the name Kanye West on
                          it, it’s supposed to be pushing the furthest possibilities. I will be the leader of a company
                          that ends up being worth billions of dollars, because I got the answers. I understand culture.
                          I am the nucleus.</p>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                      <a ngbNavLink>Settings</a>
                      <ng-template ngbNavContent>
                        <p>
                          "I will be the leader of a company that ends up being worth billions of dollars, because I got
                          the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I
                          have, to push possibilities, to show people, this is the level that things could be at."
                        </p>
                      </ng-template>
                    </li>
                  </ul>
                  <div [ngbNavOutlet]="nav2" class="mt-2 text-center"></div>
                </div>
              </div>
            </div>
            <!-- End Tabs on plain Card -->
          </div>
        </div>
      </div>
    </div>
    <div class="section section-pills">
      <div class="container">
        <div id="navigation-pills">
          <div class="title">
            <h4>Navigation Pills</h4>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p class="category">Horizontal tabs</p>
              <ul [(activeId)]="activeTab3" ngbNav #nav3="ngbNav" type="pills"
                class="nav nav-pills nav-pills-primary justify-content-start tab-space">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>Profile</a>
                  <ng-template ngbNavContent>
                    Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate
                    B2C users after installed base benefits.
                    <br />
                    <br /> Dramatically visualize customer directed convergence without revolutionary ROI.
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Profile</a>
                  <ng-template ngbNavContent>
                    Efficiently unleash cross-media information without cross-media value. Quickly maximize timely
                    deliverables for real-time schemas.
                    <br />
                    <br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink>Options</a>
                  <ng-template ngbNavContent>
                    Completely synergize resource taxing relationships via premier niche markets. Professionally
                    cultivate one-to-one customer service with robust ideas.
                    <br />
                    <br />Dynamically innovate resource-leveling customer service for state of the art customer service.
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav3" class="mt-2"></div>
            </div>
            <div class="col-md-6">
              <p class="category">Vertical tabs</p>
              <div class="nav-pills-navigation">
                <div class="nav-pills-wrapper">
                  <div class="nav nav-pills nav-pills-primary vertical-pills">
                    <ul [(activeId)]="activeTab4" ngbNav #nav4="ngbNav" type="pills" class="nav nav-pills flex-column"
                      [orientation]="'vertical'">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink>Profile</a>
                        <ng-template ngbNavContent class="tab-space">
                          Collaboratively administrate empowered markets via plug-and-play networks. Dynamically
                          procrastinate B2C users after installed base benefits.
                          <br />
                          <br /> Dramatically visualize customer directed convergence without revolutionary ROI.
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink>Settings</a>
                        <ng-template ngbNavContent>
                          Efficiently unleash cross-media information without cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                          <br />
                          <br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="3">
                        <a ngbNavLink>Options</a>
                        <ng-template ngbNavContent>
                          Completely synergize resource taxing relationships via premier niche markets. Professionally
                          cultivate one-to-one customer service with robust ideas.
                          <br />
                          <br />Dynamically innovate resource-leveling customer service for state of the art customer
                          service.
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav4" class="mt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <p class="category">With Icons</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="nav nav-pills nav-pills-primary justify-content-start tab-space nav-pills-icons">
                <ul [(activeId)]="activeTab5" ngbNav #nav5="ngbNav" type="pills"
                  class="nav nav-pills justify-content-start">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>
                      <i class="now-ui-icons objects_umbrella-13"></i> Home
                    </a>
                    <ng-template ngbNavContent>
                      Collaboratively administrate empowered markets via plug-and-play networks. Dynamically
                      procrastinate
                      B2C users after installed base benefits.
                      <br />
                      <br /> Dramatically visualize customer directed convergence without revolutionary ROI.
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>
                      <i class="now-ui-icons shopping_shop"></i> Messages
                    </a>
                    <ng-template ngbNavContent>
                      Efficiently unleash cross-media information without cross-media value. Quickly maximize timely
                      deliverables for real-time schemas.
                      <br />
                      <br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink>
                      <i class="now-ui-icons ui-2_settings-90"></i> Settings
                    </a>
                    <ng-template ngbNavContent>
                      Completely synergize resource taxing relationships via premier niche markets. Professionally
                      cultivate one-to-one customer service with robust ideas.
                      <br />
                      <br />Dynamically innovate resource-leveling customer service for state of the art customer
                      service.
                    </ng-template>
                  </li>
                </ul>
              </div>
              <div [ngbNavOutlet]="nav5" class="mt-2"></div>
            </div>
            <div class="col-md-6">
              <div class="nav-pills-navigation">
                <div class="nav-pills-wrapper">
                  <div class="nav nav-pills nav-pills-primary vertical-pills nav-pills-icons">
                    <ul [(activeId)]="activeTab6" ngbNav #nav6="ngbNav" type="pills" class="nav nav-pills flex-column"
                      [orientation]="'vertical'">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink>
                          <i class="now-ui-icons objects_umbrella-13"></i> Home
                        </a>
                        <ng-template ngbNavContent>
                          Collaboratively administrate empowered markets via plug-and-play networks. Dynamically
                          procrastinate B2C users after installed base benefits.
                          <br />
                          <br /> Dramatically visualize customer directed convergence without revolutionary ROI.
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink>
                          <i class="now-ui-icons ui-2_settings-90"></i> Settings
                        </a>
                        <ng-template ngbNavContent>
                          Efficiently unleash cross-media information without cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                          <br />
                          <br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav6" class="mt-2 tab-content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title">
            <p class="category">Just Icons</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="nav-pills-navigation">
                <div class="nav-pills-wrapper">
                  <div class="nav nav-pills nav-pills-info vertical-pills nav-pills-just-icons">
                    <ul [(activeId)]="activeTab7" ngbNav #nav7="ngbNav" type="pills" class="nav nav-pills flex-column"
                      [orientation]="'vertical'">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink>
                          <i class="now-ui-icons objects_umbrella-13"></i>
                        </a>
                        <ng-template ngbNavContent>
                          Collaboratively administrate empowered markets via plug-and-play networks. Dynamically
                          procrastinate B2C users after installed base benefits.
                          <br />
                          <br /> Dramatically visualize customer directed convergence without revolutionary ROI.
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2">
                        <a ngbNavLink>
                          <i class="now-ui-icons ui-2_settings-90"></i>
                        </a>
                        <ng-template ngbNavContent>
                          Efficiently unleash cross-media information without cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                          <br />
                          <br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="nav7" class="mt-2 tab-content"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="nav nav-pills nav-pills-info justify-content-start tab-space nav-pills-just-icons">
                <ul [(activeId)]="activeTab8" ngbNav #nav8="ngbNav" type="pills"
                  class="nav nav-pills justify-content-start">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>
                      <i class="now-ui-icons objects_umbrella-13"></i>
                    </a>
                    <ng-template ngbNavContent>
                      Collaboratively administrate empowered markets via plug-and-play networks. Dynamically
                      procrastinate
                      B2C users after installed base benefits.
                      <br />
                      <br /> Dramatically visualize customer directed convergence without revolutionary ROI.
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>
                      <i class="now-ui-icons shopping_shop"></i>
                    </a>
                    <ng-template ngbNavContent>
                      Efficiently unleash cross-media information without cross-media value. Quickly maximize timely
                      deliverables for real-time schemas.
                      <br />
                      <br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink>
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </a>
                    <ng-template ngbNavContent>
                      Completely synergize resource taxing relationships via premier niche markets. Professionally
                      cultivate one-to-one customer service with robust ideas.
                      <br />
                      <br />Dynamically innovate resource-leveling customer service for state of the art customer
                      service.
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav8" class="mt-2 tab-content"></div>
              </div>
            </div>
          </div>
          <!--                 end nav pills -->
        </div>
      </div>
    </div>
    <div class="section section-pagination">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h4>Progress Bars</h4>
            <div class="progress-container">
              <span class="progress-badge">Default</span>

              <ngb-progressbar [value]="25"><span class="progress-value">25%</span></ngb-progressbar><br>
            </div>
            <div class="progress-container progress-primary">
              <span class="progress-badge">Primary</span>
              <ngb-progressbar [value]="60"><span class="progress-value">60%</span></ngb-progressbar><br>
            </div>
            <div class="progress-container progress-danger">
              <span class="progress-badge">Danger</span>
              <ngb-progressbar [value]="60"><span class="progress-value">60%</span></ngb-progressbar><br>
            </div>
            <div class="progress-container progress-warning">
              <span class="progress-badge">Warning</span>
              <ngb-progressbar [value]="60"><span class="progress-value">60%</span></ngb-progressbar><br>
            </div>
            <div class="progress-container progress-success">
              <span class="progress-badge">Success</span>
              <ngb-progressbar [value]="60"><span class="progress-value">60%</span></ngb-progressbar><br>
            </div>
            <div class="progress-container progress-info">
              <span class="progress-badge">Info</span>
              <ngb-progressbar [value]="60"><span class="progress-value">60%</span></ngb-progressbar><br>
            </div>
          </div>
          <div class="col-md-6">
            <h4>Pagination</h4>
            <ngb-pagination [collectionSize]="50" [(page)]="page" aria-label="Default pagination"
              class="pagination-primary"></ngb-pagination>
            <br />
            <ngb-pagination [collectionSize]="50" [(page)]="page2" aria-label="Default pagination"></ngb-pagination>
            <br />
            <div class="pagination-container justify-content-center">
              <ngb-pagination [collectionSize]="60" [(page)]="page1" [maxSize]="5" [rotate]="true"
                class="pagination-info"></ngb-pagination>
            </div>
            <br>
            <h4>Labels</h4>
            <span class="badge badge-default">Default</span>
            <span class="badge badge-primary">Primary</span>
            <span class="badge badge-success">Success</span>
            <span class="badge badge-info">Info</span>
            <span class="badge badge-warning">Warning</span>
            <span class="badge badge-danger">Danger</span>
            <span class="badge badge-neutral">Neutral</span>
          </div>
        </div>
        <div class="space"></div>
      </div>
    </div>
    <div class="section" id="notifications">
      <div class="container">
        <h4>Notifications</h4>
      </div>
      <app-notification></app-notification>
    </div>
    <app-prefooter-areas></app-prefooter-areas>
    <app-footers-areas></app-footers-areas>
    <app-typography></app-typography>
    <app-tables-areas></app-tables-areas>
    <app-cards-areas></app-cards-areas>

    <div class="section section-javascript" id="javascriptComponents">
      <div class="container">
        <h3 class="title">Angular Native Components</h3>
        <div class="row" id="modals">
          <div class="col-md-6">
            <h4>Modal</h4>
            <ngbd-modal-component></ngbd-modal-component>
          </div>
          <div class="col-md-6">
            <h4>Popovers</h4>
            <button type="button" class="btn btn-default text-margin" placement="right"
              ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on right"
              popoverClass="popover-primary">
              On right
            </button>

            <button type="button" class="btn btn-default text-margin" placement="top"
              ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on top">
              On top
            </button>

            <button type="button" class="btn btn-default text-margin" placement="left"
              ngbPopover="Here will be some very useful information about this popover." popoverTitle="Popover on left">
              On left
            </button>

            <button type="button" class="btn btn-default" placement="bottom"
              ngbPopover="Here will be some very useful information about this popover."
              popoverTitle="Popover on bottom">
              On bottom
            </button>
          </div>
          <br />
          <br />
          <div class="col-md-6">
            <h4>Datetimepicker</h4>
            <div class="row">
              <div class="col-md-6">
                <div class="datepicker-container">
                  <div class="form-group">
                    <input class="form-control datetimepicker" placeholder="06/07/2017" name="dp" [(ngModel)]="model"
                      ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" data-color="orange">
                  </div>
                </div>
              </div>
            </div>
            <!--                 collapse -->
            <div id="collapse">
              <div class="title">
                <h4>Collapse</h4>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one" class="card-plain">
                    <ngb-panel id="config-panel-one">
                      <ng-template ngbPanelTitle>
                        <a class="collapsed">
                          Collapsible Group Item #1
                          <i class="now-ui-icons arrows-1_minimal-down"></i>
                        </a>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                        3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch
                        3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                        anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                        vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                        of them accusamus
                        labore sustainable VHS.
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel>
                      <ng-template ngbPanelTitle>
                        <a class="collapsed">
                          Collapsible Group Item #2
                          <i class="now-ui-icons arrows-1_minimal-down"></i>
                        </a>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                        3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch
                        3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                        anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                        vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                        of them accusamus
                        labore sustainable VHS.
                      </ng-template>
                    </ngb-panel>
                    <ngb-panel>
                      <ng-template ngbPanelTitle>
                        <a class="collapsed">
                          Collapsible Group Item #3
                          <i class="now-ui-icons arrows-1_minimal-down"></i>
                        </a>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                        3 wolf moon officia
                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch
                        3 wolf moon tempor,
                        sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                        anim keffiyeh helvetica,
                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                        vice lomo. Leggings
                        occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard
                        of them accusamus
                        labore sustainable VHS.
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>
              </div>
            </div>
            <!--                 end collapse -->
          </div>
          <div class="col-md-6">
            <h4>Tooltips</h4>
            <button type="button" class="btn btn-default btn-tooltip text-margin" placement="right"
              ngbTooltip="Tooltip on right">
              On right
            </button>
            <button type="button" class="btn btn-default btn-tooltip text-margin" placement="left"
              ngbTooltip="Tooltip on left">
              On left
            </button>
            <button type="button" class="btn btn-default btn-tooltip text-margin" placement="top"
              ngbTooltip="Tooltip on top">
              On top
            </button>
            <button type="button" class="btn btn-default btn-tooltip text-margin" placement="bottom"
              ngbTooltip="Tooltip on bottom">
              On bottom
            </button>
            <div class="clearfix"></div>
            <br>
            <br>
          </div>
          <!-- </div>
            </div> -->
        </div>
        <div id="file-uploader">
          <div class="container">
            <div class="title">
              <h4>File Uploader</h4>
            </div>
            <div class="row">
              <div class="col-md-5 col-sm-8">
                <h4><small>Regular Image</small></h4>
                <app-image-upload></app-image-upload>
              </div>
              <div class="col-md-3 col-sm-4">
                <h4><small>Avatar</small></h4>
                <app-image-upload [isRound]="true"></app-image-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="section" id="carousel">
          <div class="container">
            <div class="title">
              <h4>Carousel</h4>
            </div>
            <div class="row justify-content-center">
              <div class="col-8">
                <ngb-carousel id="carousel" [interval]="2000">
                  <ng-template ngbSlide>
                    <img class="d-block" src="assets/img/bg1.jpg" alt="First slide">
                    <div class="carousel-caption d-none d-md-block">
                      <h5>Nature, United States</h5>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <img class="d-block" src="assets/img/bg3.jpg" alt="Second slide">
                    <div class="carousel-caption d-none d-md-block">
                      <h5>Somewhere Beyond, United States</h5>
                    </div>
                  </ng-template>
                  <ng-template ngbSlide>
                    <img class="d-block" src="assets/img/bg4.jpg" alt="Third slide">
                    <div class="carousel-caption d-none d-md-block">
                      <h5>Yellowstone National Park, United States</h5>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-nucleo-icons">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <h2 class="title">Nucleo Icons</h2>
            <h5 class="description">
              Now UI Kit comes with 100 custom icons made by our friends from NucleoApp. The official package contains
              over 2.100 thin icons which are looking great in combination with Now UI Kit Make sure you check all of
              them and use those that you like the most.
            </h5>
            <a [routerLink]="['/nucleoicons']" class="btn btn-primary btn-round btn-lg">View Demo Icons</a>
            <a href="https://nucleoapp.com/?ref=1712" class="btn btn-primary btn-simple btn-round btn-lg"
              target="_blank">View All Icons</a>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="icons-container">
              <i class="now-ui-icons ui-1_send"></i>
              <i class="now-ui-icons ui-2_like"></i>
              <i class="now-ui-icons transportation_air-baloon"></i>
              <i class="now-ui-icons text_bold"></i>
              <i class="now-ui-icons tech_headphones"></i>
              <i class="now-ui-icons emoticons_satisfied"></i>
              <i class="now-ui-icons shopping_cart-simple"></i>
              <i class="now-ui-icons objects_spaceship"></i>
              <i class="now-ui-icons media-2_note-03"></i>
              <i class="now-ui-icons ui-2_favourite-28"></i>
              <i class="now-ui-icons design_palette"></i>
              <i class="now-ui-icons clothes_tie-bow"></i>
              <i class="now-ui-icons location_pin"></i>
              <i class="now-ui-icons objects_key-25"></i>
              <i class="now-ui-icons travel_istanbul"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer section-dark">
    <div class="container">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li><a href="https://www.brightenconsulting.com">Brighten Digital</a></li>
            <li><a href="http://blog.brightenconsulting.com">Blog</a></li>
            <li><a href="https://www.brightenconsulting.com/license">Licenses</a></li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <span class="copyright">
            © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Brighten Digital
          </span>
        </div>
      </div>
    </div>
  </footer>

</div>