import { Component, OnInit } from '@angular/core';
import { Team } from 'app/interfaces/team';
import { TeamService } from 'app/services/team.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  team: Team[] = [];
  teamVisible: boolean = false;
  visible: boolean = false;
  constructor(private teamService: TeamService) { }

  getTeam(): Team[]{
    this.team = this.teamService.getTeam();
    return this.team;
  }

  ngOnInit() {
    this.getTeam();
  }


}
