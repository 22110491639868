<div class="section section-basic">
    <div class="container">
      <h3 class="title">Basic Elements</h3>
      <h4>Buttons</h4>
      <p class="category">Pick your style</p>
      <div class="row">
          <div class="col-md-10">
              <button class="btn btn-primary" type="button">Default</button>
              <button class="btn btn-primary btn-round" type="button">Round</button>
              <button class="btn btn-primary btn-round" type="button">
                  <i class="now-ui-icons ui-2_favourite-28"></i> With Icon
              </button>
              <button class="btn btn-primary btn-icon btn-round" type="button">
                  <i class="now-ui-icons ui-2_favourite-28"></i>
              </button>
              <button class="btn btn-primary btn-simple btn-round" type="button">Simple</button>
          </div>
      </div>
      <p class="category">Pick your size</p>
      <div class="row">
          <div class="col-md-10">
              <button class="btn btn-primary btn-sm">Small</button>
              <button class="btn btn-primary">Regular</button>
              <button class="btn btn-primary btn-lg">Large</button>
          </div>
      </div>
      <p class="category">Pick your color</p>
      <div class="row">
          <div class="col-md-10">
              <button class="btn">Default</button>
              <button class="btn btn-primary">Primary</button>
              <button class="btn btn-info">Info</button>
              <button class="btn btn-success">Success</button>
              <button class="btn btn-warning">Warning</button>
              <button class="btn btn-neutral">Neutral</button>
          </div>
      </div>
      <h4>Links</h4>
      <div class="row">
          <div class="col-md-8">
              <button class="btn btn-link">Default</button>
              <button class="btn btn-link btn-primary ">Primary</button>
              <button class="btn btn-link btn-info">Info</button>
              <button class="btn btn-link btn-success">Success</button>
              <button class="btn btn-link btn-warning">Warning</button>
              <button class="btn btn-link btn-danger">Danger</button>
          </div>
      </div>
      <!--                 social buttons		         -->
      <div id="social-buttons">
          <h4>Social Buttons</h4>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <p class="category">Default</p>
                  <button class="btn btn-twitter">
                      <i class="fa fa-twitter"></i> Connect with Twitter
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <p class="category">&nbsp;</p>
                  <button class="btn btn-icon btn-twitter">
                      <i class="fa fa-twitter"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <p class="category">&nbsp;</p>
                  <button class="btn btn-icon btn-round btn-twitter">
                      <i class="fa fa-twitter"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <p class="category">Neutral</p>
                  <button class="btn btn-icon btn-neutral btn-twitter">
                      <i class="fa fa-twitter"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <p class="category">&nbsp;</p>
                  <button class="btn btn-neutral btn-twitter">
                      <i class="fa fa-twitter"></i> Connect with Twitter
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-facebook">
                      <i class="fa fa-facebook-square"></i> Share · 2.2k
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-facebook">
                      <i class="fa fa-facebook"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-facebook">
                      <i class="fa fa-facebook"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-facebook">
                      <i class="fa fa-facebook-square"> </i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-facebook">
                      <i class="fa fa-facebook-square"></i> Share · 2.2k
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-google">
                      <i class="fa fa-google"></i> Share on Google+
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-google">
                      <i class="fa fa-google"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-google">
                      <i class="fa fa-google"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-google">
                      <i class="fa fa-google"> </i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-google">
                      <i class="fa fa-google"></i> Share on Google+
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-linkedin">
                      <i class="fa fa-linkedin-square"></i> Connect with Linkedin
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-linkedin">
                      <i class="fa fa-linkedin"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-linkedin">
                      <i class="fa fa-linkedin"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-linkedin">
                      <i class="fa fa-linkedin-square"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-linkedin">
                      <i class="fa fa-linkedin-square"></i> Connect with Linkedin
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-pinterest">
                      <i class="fa fa-pinterest"></i> Pint it · 212
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-pinterest">
                      <i class="fa fa-pinterest"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-pinterest">
                      <i class="fa fa-pinterest"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-pinterest">
                      <i class="fa fa-pinterest"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-pinterest">
                      <i class="fa fa-pinterest"></i> Pint it · 212
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-youtube">
                      <i class="fa fa-youtube-play"></i> View on Youtube
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-youtube">
                      <i class="fa fa-youtube"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-youtube">
                      <i class="fa fa-youtube"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-youtube">
                      <i class="fa fa-youtube"> </i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-youtube">
                      <i class="fa fa-youtube-play"></i> View on Youtube
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-tumblr">
                      <i class="fa fa-tumblr-square"></i> Repost
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-tumblr">
                      <i class="fa fa-tumblr"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-tumblr">
                      <i class="fa fa-tumblr"> </i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-tumblr">
                      <i class="fa fa-tumblr-square"> </i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-tumblr">
                      <i class="fa fa-tumblr-square"></i> Repost
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-github">
                      <i class="fa fa-github"></i> Connect with Github
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-github">
                      <i class="fa fa-github"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-github">
                      <i class="fa fa-github"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-github">
                      <i class="fa fa-github"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-github">
                      <i class="fa fa-github"></i> Connect with Github
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-behance">
                      <i class="fa fa-behance-square"></i> Follow us
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-behance">
                      <i class="fa fa-behance"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-behance">
                      <i class="fa fa-behance"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-behance">
                      <i class="fa fa-behance"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-behance">
                      <i class="fa fa-behance-square"></i> Follow us
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-dribbble">
                      <i class="fa fa-dribbble"></i> Find us on Dribble
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-dribbble">
                      <i class="fa fa-dribbble"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-dribbble">
                      <i class="fa fa-dribbble"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-dribbble">
                      <i class="fa fa-dribbble"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-dribbble">
                      <i class="fa fa-dribbble"></i> Find us on Dribble
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4 col-sm-5">
                  <button class="btn btn-reddit">
                      <i class="fa fa-reddit"></i> Repost · 232
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon  btn-reddit">
                      <i class="fa fa-reddit"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-round btn-reddit">
                      <i class="fa fa-reddit"></i>
                  </button>
              </div>
              <div class="col-md-1 col-sm-1">
                  <button class="btn btn-icon btn-neutral btn-reddit">
                      <i class="fa fa-reddit"></i>
                  </button>
              </div>
              <div class="col-md-3 col-sm-4">
                  <button class="btn btn-neutral btn-reddit">
                      <i class="fa fa-reddit"></i> Repost · 232
                  </button>
              </div>
          </div>
      </div>
      <!--                 end social buttons -->
      <div class="space-70"></div>
      <div id="inputs">
          <h4>Inputs</h4>
          <p class="category">Form Controls</p>
          <div class="row">
              <div class="col-sm-6 col-lg-3">
                  <div class="form-group">
                      <input type="text" value="" placeholder="Regular" class="form-control" />
                  </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                  <div class="form-group has-success">
                      <input type="text" value="Success" class="form-control form-control-success" />
                  </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                  <div class="form-group has-danger">
                      <input type="email" value="Error Input" class="form-control form-control-danger" />
                  </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                  <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                    <div class="input-group-prepend" >
                        <span class="input-group-text"><i class="fa fa-user-circle"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Left Font Awesome Icon" (focus)="focus=true" (blur)="focus=false" >
                  </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                  <div class="input-group" [ngClass]="{'input-group-focus':focus1===true}">
                    <input type="text" class="form-control" placeholder="Right Nucleo Icon" (focus)="focus1=true" (blur)="focus1=false">
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="now-ui-icons users_single-02"></i></span>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="space-70"></div>
      <div class="select">
          <div class="row">
              <div class="col-md-6">
                  <div class="title">
                      <h4>Customizable Select</h4>
                  </div>
                  <div class="row">
                      <div class="col-lg-6 col-md-8 col-sm-5">
                        <angular2-multiselect [data]="dropdownList1" [(ngModel)]="selectedItems1"
                            [settings]="dropdownSettings1"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
                        </angular2-multiselect>
                      </div>
                      <div class="col-lg-6 col-md-8 col-sm-5">
                        <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                            [settings]="dropdownSettings"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
                        </angular2-multiselect>
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="title">
                      <h4>Dropdown & Dropup</h4>
                  </div>
                  <div class="row">
                      <div class="col-lg-5 col-md-8">
                        <div ngbDropdown class="dropdown">
                            <button class="btn btn-primary btn-round dropdown-toggle" ngbDropdownToggle>Dropdown</button>
                            <div ngbDropdownMenu>
                              <a class="dropdown-item" href="#">Action</a>
                              <a class="dropdown-item" href="#">Another action</a>
                              <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </div>
                      </div>
                      <div class="col-lg-5 col-md-8">
                          <div ngbDropdown placement="top-right" class="d-inline-block">
                            <button class="btn btn-primary btn-round dropdown-toggle" id="dropdownBasic2" ngbDropdownToggle>Toggle dropup</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                              <a class="dropdown-item" href="#">Action</a>
                              <a class="dropdown-item" href="#">Another action</a>
                              <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--                 end select -->
      <div class="space-70"></div>
      <div id="textareaTags">
          <div class="row">
              <div class="col-md-6">
                  <div class="title">
                      <h4>Textarea</h4>
                  </div>
                  <textarea class="form-control" name="name" rows="4" cols="80" placeholder="You can write your text here..."></textarea>
              </div>
              <div class="col-md-6">
                  <div class="title">
                      <h4>Tags</h4>
                  </div>
                  <tag-input [(ngModel)]='tagItems' theme='regular-theme'></tag-input>
                  <!-- You can change data-color="rose" with one of our colors primary | warning | info | danger | success -->
              </div>
          </div>
      </div>
      <div class="space-70"></div>
      <div class="row" id="checkRadios">
          <div class="col-sm-6 col-lg-3">
              <p class="category">Checkboxes</p>
              <div class="form-check">
                  <label class="form-check-label">
                      <input class="form-check-input" type="checkbox">
                      <span class="form-check-sign"></span>
                      Unchecked
                  </label>
              </div>
              <div class="form-check">
                  <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" checked>
                      <span class="form-check-sign"></span>
                      Checked
                  </label>
              </div>
              <div class="form-check disabled">
                  <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" disabled>
                      <span class="form-check-sign"></span>
                      Disabled Unchecked
                  </label>
              </div>
              <div class="form-check disabled">
                  <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" checked disabled>
                      <span class="form-check-sign"></span>
                      Disabled Checked
                  </label>
              </div>
          </div>
          <div class="col-sm-6 col-lg-3">
              <p class="category">Radios</p>
              <div class="form-check form-check-radio">
                  <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1">
                      <span class="form-check-sign"></span>
                      Radio is off
                  </label>
              </div>
              <div class="form-check form-check-radio">
                  <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option2" checked>
                      <span class="form-check-sign"></span>
                      Radio is on
                  </label>
              </div>
              <div class="form-check form-check-radio disabled">
                  <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="option3" disabled>
                      <span class="form-check-sign"></span>
                      Disabled radio is off
                  </label>
              </div>
              <div class="form-check form-check-radio disabled">
                  <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios2" value="option4" disabled checked>
                      <span class="form-check-sign"></span>
                      Disabled radio is on
                  </label>
              </div>
          </div>
          <div class="col-sm-6 col-lg-3">
              <p class="category">Toggle Buttons</p>
              <bSwitch
                  [(ngModel)]="state">
              </bSwitch>
              <bSwitch> Toggle is off
              </bSwitch>
          </div>
          <div class="col-sm-6 col-lg-3">
              <p class="category">Sliders</p>
              <nouislider class="slider" [min]="0" [max]="100" [step]="1" [(ngModel)]="simpleSlider" [tooltips]="true"></nouislider>
              <br>
              <nouislider class="slider slider-primary" [connect]="true" [min]="0" [max]="100" [step]="1" [(ngModel)]="doubleSlider" [tooltips]="true"></nouislider>
          </div>
      </div>
    </div>
</div>
