<div class="row">
    <div class="col-md-12 pr-2">
        <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
          <div class="input-group-prepend">
              <span class="input-group-text"><i class="now-ui-icons ui-1_zoom-bold"></i></span>
          </div>
        <input type="text" style="background-color: #fff;" class="form-control" autofocus placeholder="Search for Automation and RPA projects..." (keydown)="search($event.target.value)" (focus)="focus=true" (blur)="focus=false">
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-4" *ngFor="let proj of rpaProjs">
        <div class="card card-background card-raised" data-background-color style="background-image:url('{{ getImage(proj) }}')">
            <div class="info">
                <div class="description">
                    <h4 class="info-title">{{proj.title}}</h4>
                    <div class="darkbox-text">{{proj.short}}</div>
                    <ngbd-modal-high-component [proj]="proj"></ngbd-modal-high-component>
                </div>
            </div>
        </div>
    </div>
</div>
