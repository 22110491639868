<div class="wrapper">
  
    <div class="features-4">
        <h2 class="title" style="text-align: center;">Brighten Digital portfolio by industry</h2>
        
        <div class="container">
            <div class="row">
                <div class="col-md-12 pr-2">
                    <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="now-ui-icons ui-1_zoom-bold"></i></span>
                        </div>
                        <input type="text" style="background-color: #fff;" class="form-control" autofocus placeholder="Search projects..." (keydown)="search($event.target.value)" (focus)="focus=true" (blur)="focus=false">
                    </div>
                </div>
            </div>
        </div>
            
        <div class="container" style="padding:0px">
            <app-automotive-ind [keywords]="keywords" id="auto-ind"></app-automotive-ind>
            <app-banking-ind [keywords]="keywords" id="bank-ind"></app-banking-ind>
            <app-insurance-ind [keywords]="keywords" id="ins-ind"></app-insurance-ind>
            <app-logistics-ind [keywords]="keywords" id="log-ind"></app-logistics-ind>
            <app-pharma-ind [keywords]="keywords" id="ph-ind"></app-pharma-ind>
            <app-retail-ind [keywords]="keywords" id="ret-ind"></app-retail-ind>

        </div>
    </div>

</div>

<app-contacts id="get-in-touch"></app-contacts>
<app-footer-main></app-footer-main>