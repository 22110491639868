import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Project } from "app/interfaces/project";
import { ProjectsService } from "app/services/projects.service";

@Component({
	selector: "app-insurance-ind",
	templateUrl: "./insurance-ind.component.html",
	styleUrls: ["./insurance-ind.component.scss"],
})
export class InsuranceIndComponent implements OnInit, OnChanges {
	insuranceProjs: Project[] = [];

	@Input() keywords : string = null;
	constructor(private projectsService: ProjectsService) {}

	ngOnInit() {
		this.getProjects();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.getProjects();
	}

	getProjects(): void {
		this.insuranceProjs = this.projectsService.getInsurance(this.keywords);
	}

	getImage(a : any){
		return a.attach[0].src || a.attach[0];
	}

}
