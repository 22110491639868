import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rpa',
  templateUrl: './rpa.component.html',
  styleUrls: ['./rpa.component.scss']
})
export class RPAComponent implements OnInit {
  constructor(){
    
  }
 
  ngOnInit() {
    
  }

}
