import { Injectable } from "@angular/core";
import { Project } from "../interfaces/project";
import projsJson from '../../assets/projects.json';

@Injectable({
	providedIn: "root",
})
export class ProjectsService {
	projs: Project[] = projsJson;  

	constructor () {
	}


	analyticsProjs: Project[] = [];
	rpaProjs: Project[] = [];
	softwareProjs: Project[] = [];
	staffAreaProjs: Project[] = [];
	staffIndustryProjs: Project[] = [];
	staffAllProjs: Project[] = [];
	automotiveProjs: Project[] = [];
	pharmaProjs: Project[] = [];
	retailProjs: Project[] = [];
	logisticsProjs: Project[] = [];
	bankingProjs: Project[] = [];
	insuranceProjs: Project[] = [];

	getAnalytics(): Project[] {
		this.analyticsProjs = [];
		for (let p of this.projs) {
			if (p.area?.toLocaleLowerCase().includes("analytics") || p.area?.toLocaleLowerCase().includes("business intelligence")) {
				this.analyticsProjs.push(p);
			}
		}
		return this.analyticsProjs;
	}

	getRPA(): Project[] {
		this.rpaProjs = [];
		for (let p of this.projs) {
			if (p.area?.toLocaleLowerCase().includes("rpa") || p.area?.toLocaleLowerCase().includes("automation")) {
				this.rpaProjs.push(p);
			}
		}
		return this.rpaProjs;
	}

	getSoftware(): Project[] {
		this.softwareProjs = [];
		for (let p of this.projs) {
			if (p.area?.toLocaleLowerCase().includes("software") || p.area?.toLocaleLowerCase().includes("software engineering")) {
				this.softwareProjs.push(p);
			}
		}
		return this.softwareProjs;
	}

	getStaffAreas(): Project[] {
		this.staffAreaProjs = [];
		for (let p of this.projs) {
			if (p.areaHigh) {
				this.staffAreaProjs.push(p);
			}
		}
		return this.staffAreaProjs;
	}

	getStaffIndustries(): Project[] {
		this.staffIndustryProjs = [];
		for (let p of this.projs) {
			if (p.industryHigh) {
				this.staffIndustryProjs.push(p);
			}
		}
		return this.staffIndustryProjs;
	}

	getStaffAll(): Project[] {
		this.staffAllProjs = [];
		for (let p of this.projs) {
			if (p.areaHigh || p.industryHigh) {
				this.staffAllProjs.push(p);
			}
		}
		return this.staffAllProjs;
	}

	getProjects(keywords: string = null): Project[] {
		return this.projs.filter(x=> {
			return keywords == null || keywords == '' ||
				x.area.toLowerCase().includes(keywords) ||
				x.description.toLowerCase().includes(keywords) ||
				x.industry.toLowerCase().includes(keywords) ||
				x.location.toLowerCase().includes(keywords) ||
				x.short.toLowerCase().includes(keywords) ||
				x.title.toLowerCase().includes(keywords)
		});
	}

	getAutomotive(keywords: string = null): Project[] {
		return this.getProjects(keywords).filter(x=> {
			return x.industry?.toLocaleLowerCase().includes('automotive')
		});
	}

	getPharma(keywords: string = null): Project[] {
		return this.getProjects(keywords).filter(x=> {
			return x.industry?.toLocaleLowerCase().includes('pharma')
		});
	}

	getRetail(keywords : string = null): Project[] {
		return this.getProjects(keywords).filter(x=> {
			return x.industry?.toLocaleLowerCase().includes('retail')
		});
	}

	getLogistics(keywords : string = null): Project[] {
		return this.getProjects(keywords).filter(x=> {
			return x.industry?.toLocaleLowerCase().includes('logistics')
		});
	}

	getBanking(keywords: string = null): Project[] {
		return this.getProjects(keywords).filter(x=> {
			return x.industry?.toLocaleLowerCase().includes('banking')
		});
	}

	getInsurance(keywords : string = null): Project[] {
		return this.getProjects(keywords).filter(x=> {
			return x.industry?.toLocaleLowerCase().includes('insurance')
		});
	}
}
