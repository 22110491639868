
<button class="btn btn-light text-margin" (click)="open(Notice, '', 'lg');$event.stopPropagation();" *ngIf="!imageButton">
    {{text}}
</button>

<div (click)="open(Notice, '', 'lg'); $event.stopPropagation();" *ngIf="imageButton" style="cursor: pointer;">
    <a><i class="now-ui-icons travel_info" style="font-size: 2.5em;" ></i></a>
</div>

<ng-template #Notice let-c="close" let-d="dismiss">
    <div style="position: relative;">
        <div class="modal-header" style="position: relative;">
            <h5 class="modal-title"  style="font-size:1.8em">
                <span style="color:#e52449;">{{proj.area || proj.category}} | </span>
                <span>{{proj.title}}</span>
            </h5>           
        </div>
        <div class="modal-body" style="padding-top: 10px;">
            <div class="instruction">
                <div class="row">
                    <div class="col-md-12" *ngIf="!isPreview">
                        <div class="description" style="color: #5e5e5e;font-weight: 400;font-size: 1.3em;">
                            <span [innerHtml]="safeDescription"></span>                            
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="isPreview || !multipleViews">
                        <app-slideshow [attachs]="proj.attach || proj.media"></app-slideshow>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-center" style="display: inline;text-align: center;">
        <p style="text-align:center;font-weight:400;font-size:1em"></p>
        <p></p>
        <button type="button" class="btn btn-warning btn-round" (click)="preview(true)" *ngIf="!isPreview && multipleViews" style="margin-right: 15px;">PREVIEW</button>
        <button type="button" class="btn btn-info btn-round" (click)="preview(false)" *ngIf="isPreview && proj?.url">BACK</button>
        <button type="button" class="btn btn-info btn-round" (click)="navigateToExternalLink(proj?.url)" *ngIf="!isPreview && proj?.url">TRY</button>
    </div>
</ng-template>

